import list from 'src/modules/preferredModeOfContact/list/preferredModeOfContactListReducers';
import form from 'src/modules/preferredModeOfContact/form/preferredModeOfContactFormReducers';
import view from 'src/modules/preferredModeOfContact/view/preferredModeOfContactViewReducers';
import destroy from 'src/modules/preferredModeOfContact/destroy/preferredModeOfContactDestroyReducers';
import importerReducer from 'src/modules/preferredModeOfContact/importer/preferredModeOfContactImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
