import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import Roles from 'src/security/roles';
// import config from 'src/config';

const permissions = Permissions.values;

export default [
  {
    path: '/dashboard',
    exact: true,
    icon: 'fas fa-th-large',
    label: i18n('dashboard.menu'),
    permissionRequired: Roles.values.admin,
    group: 'admin',
  },

  // config.isPlanEnabled && {
  //   path: '/plan',
  //   permissionRequired: permissions.planRead,
  //   icon: 'fas fa-credit-card',
  //   label: i18n('plan.menu'),
  // },

  {
    path: '/user',
    label: i18n('user.menu'),
    permissionRequired: permissions.userRead,
    icon: 'fas fa-user-plus',
    group: 'admin',
  },

  {
    path: '/audit-logs',
    icon: 'fas fa-history',
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogRead,
    group: 'admin',
  },

  {
    path: '/settings',
    icon: 'fas fa-cog',
    label: i18n('settings.menu'),
    permissionRequired: permissions.settingsEdit,
    group: 'admin',
  },

  {
    path: '/preferred-mode-of-contact',
    permissionRequired: permissions.preferredModeOfContactRead,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.preferredModeOfContact.menu'),
    group: 'admin',
  },

  {
    path: '/car',
    permissionRequired: permissions.carCreate,
    icon: 'fas fa-truck-monster',
    label: i18n('entities.car.menu'),
    group: 'Car',
  },

  {
    path: '/location',
    permissionRequired: permissions.locationEdit,
    icon: 'fas fa-location-arrow',
    label: i18n('entities.location.menu'),
    group: 'Location',
  },

  {
    path: '/preferred-mode-of-contact',
    permissionRequired: permissions.preferredModeOfContactCreate,
    icon: 'fas fa-address-book',
    label: i18n('entities.preferredModeOfContact.menu'),
    group: 'Payment',
  },

  {
    path: '/car-makes',
    permissionRequired: permissions.carMakesEdit,
    icon: 'fas fa-asterisk',
    label: i18n('entities.carMakes.menu'),
    group: 'Car',
  },
  
  {
    path: '/car-model',
    permissionRequired: permissions.carModelEdit,
    icon: 'fas fa-gem',
    label: i18n('entities.carModel.menu'),
    group: 'Car',
  },

  {
    path: '/client-request-to-mechanic',
    permissionRequired: permissions.clientRequestToMechanicRead,
    icon: 'fas fa-question',
    label: i18n('entities.clientRequestToMechanic.menu'),
    group: 'Requests',
  },

  {
    path: '/mechanic',
    permissionRequired: permissions.mechanicCreate,
    icon: 'fas fa-tools',
    label: i18n('entities.mechanic.menu'),
    group: 'Mechanic',
  },

  {
    path: '/payment-method',
    permissionRequired: permissions.paymentMethodCreate,
    icon: 'fas fa-credit-card',
    label: i18n('entities.paymentMethod.menu'),
    group: 'Payment',
  },

  {
    path: '/payment',
    permissionRequired: permissions.paymentRead,
    icon: 'fas fa-handshake',
    label: i18n('entities.payment.menu'),
    group: 'Payment',
  },

  {
    path: '/identification-document',
    permissionRequired: permissions.identificationDocumentDestroy,
    icon: 'fas fa-id-card',
    label: i18n('entities.identificationDocument.menu'),
    group: 'Mechanic',
  },

  {
    path: '/certificate-of-registration',
    permissionRequired: permissions.certificateOfRegistrationCreate,
    icon: 'fas fa-file',
    label: i18n('entities.certificateOfRegistration.menu'),
    group: 'Mechanic',
  },  

  {
    path: '/mechanic-response-to-client-request',
    permissionRequired: permissions.mechanicResponseToClientRequestCreate,
    icon: 'fas fa-reply',
    label: i18n('entities.mechanicResponseToClientRequest.menu'),
    group: 'Requests',
  },

  {
    path: '/clients-response-to-mechanic-response',
    permissionRequired: permissions.clientsResponseToMechanicResponseRead,
    icon: 'fas fa-reply-all',
    label: i18n('entities.clientsResponseToMechanicResponse.menu'),
    group: 'Requests',
  },

  {
    path: '/garage',
    permissionRequired: permissions.garageCreate,
    icon: 'fas fa-warehouse',
    label: i18n('entities.garage.menu'),
    group: 'Mechanic',
  },

  {
    path: '/reviews-of-garages',
    permissionRequired: permissions.reviewsOfGaragesCreate,
    icon: 'fas fa-star-half-alt',
    label: i18n('entities.reviewsOfGarages.menu'),
    group: 'Mechanic',
  },

  {
    path: '/services-offered',
    permissionRequired: permissions.servicesOfferedRead,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.servicesOffered.menu'),
    group: 'Services',
  },

  {
    path: '/review-of-mechanic',
    permissionRequired: permissions.reviewOfMechanicCreate,
    icon: 'fas fa-smile',
    label: i18n('entities.reviewOfMechanic.menu'),
    group: 'Mechanic',
  },

  {
    path: '/response-document',
    permissionRequired: permissions.responseDocumentDestroy,
    icon: 'fas fa-file-upload',
    label: i18n('entities.responseDocument.menu'),
    group: 'Mechanic',
  },

  {
    path: '/client-order',
    permissionRequired: permissions.clientOrderRead,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.clientOrder.menu'),
    group: 'Order',
  },

  {
    path: '/convenience-fee',
    permissionRequired: permissions.convenienceFeeCreate,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.convenienceFee.menu'),
    group: 'Order',
  },

  {
    path: '/shop',
    permissionRequired: permissions.shopRead,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.shop.menu'),
    group: 'Shop',
  },  

  {
    path: '/shop-owner',
    permissionRequired: permissions.shopOwnerRead,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.shopOwner.menu'),
    group: 'Shop',
  },

  {
    path: '/shop-product',
    permissionRequired: permissions.shopProductRead,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.shopProduct.menu'),
    group: 'Shop',
  },

  {
    path: '/shop-product-category',
    permissionRequired: permissions.shopProductCategoryRead,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.shopProductCategory.menu'),
    group: 'Shop',
  },

  {
    path: '/advertisements',
    permissionRequired: permissions.advertisementsRead,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.advertisements.menu'),
    group: 'Advert',
  },

  {
    path: '/advert-payment',
    permissionRequired: permissions.advertPaymentRead,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.advertPayment.menu'),
    group: 'Advert',
  },

  {
    path: '/advert-package',
    permissionRequired: permissions.advertPackageRead,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.advertPackage.menu'),
    group: 'Advert',
  },

  {
    path: '/ad-package-order',
    permissionRequired: permissions.adPackageOrderRead,
    icon: 'fas fa-chevron-right',
    label: i18n('entities.adPackageOrder.menu'),
    group:'Advert',
  },  
].filter(Boolean);
