import list from 'src/modules/reviewOfMechanic/list/reviewOfMechanicListReducers';
import form from 'src/modules/reviewOfMechanic/form/reviewOfMechanicFormReducers';
import view from 'src/modules/reviewOfMechanic/view/reviewOfMechanicViewReducers';
import destroy from 'src/modules/reviewOfMechanic/destroy/reviewOfMechanicDestroyReducers';
import importerReducer from 'src/modules/reviewOfMechanic/importer/reviewOfMechanicImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
