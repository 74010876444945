import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/car/importer/carImporterSelectors';
import CarService from 'src/modules/car/carService';
import fields from 'src/modules/car/importer/carImporterFields';
import { i18n } from 'src/i18n';

const carImporterActions = importerActions(
  'CAR_IMPORTER',
  selectors,
  CarService.import,
  fields,
  i18n('entities.car.importer.fileName'),
);

export default carImporterActions;