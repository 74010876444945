import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'servicesName',
    label: i18n('entities.servicesOffered.fields.servicesName'),
    schema: schemas.string(
      i18n('entities.servicesOffered.fields.servicesName'),
      {
        "required": true,
        "min": 3
      },
    ),
  },
  {
    name: 'servicePrice',
    label: i18n('entities.servicesOffered.fields.servicePrice'),
    schema: schemas.integer(
      i18n('entities.servicesOffered.fields.servicePrice'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'allowsFreeCheckout',
    label: i18n('entities.servicesOffered.fields.allowsFreeCheckout'),
    schema: schemas.boolean(
      i18n('entities.servicesOffered.fields.allowsFreeCheckout'),
      {},
    ),
  },
  {
    name: 'requiredCheckoutAmount',
    label: i18n('entities.servicesOffered.fields.requiredCheckoutAmount'),
    schema: schemas.string(
      i18n('entities.servicesOffered.fields.requiredCheckoutAmount'),
      {},
    ),
  },
];