import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/convenienceFee/importer/convenienceFeeImporterSelectors';
import ConvenienceFeeService from 'src/modules/convenienceFee/convenienceFeeService';
import fields from 'src/modules/convenienceFee/importer/convenienceFeeImporterFields';
import { i18n } from 'src/i18n';

const convenienceFeeImporterActions = importerActions(
  'CONVENIENCEFEE_IMPORTER',
  selectors,
  ConvenienceFeeService.import,
  fields,
  i18n('entities.convenienceFee.importer.fileName'),
);

export default convenienceFeeImporterActions;