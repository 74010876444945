import list from 'src/modules/shopProduct/list/shopProductListReducers';
import form from 'src/modules/shopProduct/form/shopProductFormReducers';
import view from 'src/modules/shopProduct/view/shopProductViewReducers';
import destroy from 'src/modules/shopProduct/destroy/shopProductDestroyReducers';
import importerReducer from 'src/modules/shopProduct/importer/shopProductImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
