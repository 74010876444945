import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.clientsResponseToMechanicResponse.fields.id'),
  },
  {
    name: 'response',
    label: i18n('entities.clientsResponseToMechanicResponse.fields.response'),
  },
  {
    name: 'reason',
    label: i18n('entities.clientsResponseToMechanicResponse.fields.reason'),
  },
  {
    name: 'documents',
    label: i18n('entities.clientsResponseToMechanicResponse.fields.documents'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'clientResponseToMechanicResponse',
    label: i18n('entities.clientsResponseToMechanicResponse.fields.clientResponseToMechanicResponse'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.clientsResponseToMechanicResponse.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.clientsResponseToMechanicResponse.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
