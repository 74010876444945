import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'categoryName',
    label: i18n('entities.shopProductCategory.fields.categoryName'),
    schema: schemas.string(
      i18n('entities.shopProductCategory.fields.categoryName'),
      {},
    ),
  },
  {
    name: 'categoryDescription',
    label: i18n('entities.shopProductCategory.fields.categoryDescription'),
    schema: schemas.string(
      i18n('entities.shopProductCategory.fields.categoryDescription'),
      {
        "max": 3000
      },
    ),
  },
  {
    name: 'categoryProducts',
    label: i18n('entities.shopProductCategory.fields.categoryProducts'),
    schema: schemas.relationToMany(
      i18n('entities.shopProductCategory.fields.categoryProducts'),
      {},
    ),
  },
];