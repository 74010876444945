import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/mechanic/importer/mechanicImporterSelectors';
import MechanicService from 'src/modules/mechanic/mechanicService';
import fields from 'src/modules/mechanic/importer/mechanicImporterFields';
import { i18n } from 'src/i18n';

const mechanicImporterActions = importerActions(
  'MECHANIC_IMPORTER',
  selectors,
  MechanicService.import,
  fields,
  i18n('entities.mechanic.importer.fileName'),
);

export default mechanicImporterActions;