import list from 'src/modules/certificateOfRegistration/list/certificateOfRegistrationListReducers';
import form from 'src/modules/certificateOfRegistration/form/certificateOfRegistrationFormReducers';
import view from 'src/modules/certificateOfRegistration/view/certificateOfRegistrationViewReducers';
import destroy from 'src/modules/certificateOfRegistration/destroy/certificateOfRegistrationDestroyReducers';
import importerReducer from 'src/modules/certificateOfRegistration/importer/certificateOfRegistrationImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
