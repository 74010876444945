import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/certificateOfRegistration/importer/certificateOfRegistrationImporterSelectors';
import CertificateOfRegistrationService from 'src/modules/certificateOfRegistration/certificateOfRegistrationService';
import fields from 'src/modules/certificateOfRegistration/importer/certificateOfRegistrationImporterFields';
import { i18n } from 'src/i18n';

const certificateOfRegistrationImporterActions = importerActions(
  'CERTIFICATEOFREGISTRATION_IMPORTER',
  selectors,
  CertificateOfRegistrationService.import,
  fields,
  i18n('entities.certificateOfRegistration.importer.fileName'),
);

export default certificateOfRegistrationImporterActions;