import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/reviewsOfGarages/importer/reviewsOfGaragesImporterSelectors';
import ReviewsOfGaragesService from 'src/modules/reviewsOfGarages/reviewsOfGaragesService';
import fields from 'src/modules/reviewsOfGarages/importer/reviewsOfGaragesImporterFields';
import { i18n } from 'src/i18n';

const reviewsOfGaragesImporterActions = importerActions(
  'REVIEWSOFGARAGES_IMPORTER',
  selectors,
  ReviewsOfGaragesService.import,
  fields,
  i18n('entities.reviewsOfGarages.importer.fileName'),
);

export default reviewsOfGaragesImporterActions;