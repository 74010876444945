import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/identificationDocument/importer/identificationDocumentImporterSelectors';
import IdentificationDocumentService from 'src/modules/identificationDocument/identificationDocumentService';
import fields from 'src/modules/identificationDocument/importer/identificationDocumentImporterFields';
import { i18n } from 'src/i18n';

const identificationDocumentImporterActions = importerActions(
  'IDENTIFICATIONDOCUMENT_IMPORTER',
  selectors,
  IdentificationDocumentService.import,
  fields,
  i18n('entities.identificationDocument.importer.fileName'),
);

export default identificationDocumentImporterActions;