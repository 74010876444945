import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.shopOwner.fields.id'),
  },
  {
    name: 'isAlsoUser',
    label: i18n('entities.shopOwner.fields.isAlsoUser'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'shops',
    label: i18n('entities.shopOwner.fields.shops'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'shopOwnersAdverts',
    label: i18n('entities.shopOwner.fields.shopOwnersAdverts'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'shopOwnersPackageSubscription',
    label: i18n('entities.shopOwner.fields.shopOwnersPackageSubscription'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.shopOwner.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.shopOwner.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
