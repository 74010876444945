import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/clientRequestToMechanic/importer/clientRequestToMechanicImporterSelectors';
import ClientRequestToMechanicService from 'src/modules/clientRequestToMechanic/clientRequestToMechanicService';
import fields from 'src/modules/clientRequestToMechanic/importer/clientRequestToMechanicImporterFields';
import { i18n } from 'src/i18n';

const clientRequestToMechanicImporterActions = importerActions(
  'CLIENTREQUESTTOMECHANIC_IMPORTER',
  selectors,
  ClientRequestToMechanicService.import,
  fields,
  i18n('entities.clientRequestToMechanic.importer.fileName'),
);

export default clientRequestToMechanicImporterActions;