import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/shopOwner/importer/shopOwnerImporterSelectors';
import ShopOwnerService from 'src/modules/shopOwner/shopOwnerService';
import fields from 'src/modules/shopOwner/importer/shopOwnerImporterFields';
import { i18n } from 'src/i18n';

const shopOwnerImporterActions = importerActions(
  'SHOPOWNER_IMPORTER',
  selectors,
  ShopOwnerService.import,
  fields,
  i18n('entities.shopOwner.importer.fileName'),
);

export default shopOwnerImporterActions;