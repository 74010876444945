import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/reviewOfMechanic/importer/reviewOfMechanicImporterSelectors';
import ReviewOfMechanicService from 'src/modules/reviewOfMechanic/reviewOfMechanicService';
import fields from 'src/modules/reviewOfMechanic/importer/reviewOfMechanicImporterFields';
import { i18n } from 'src/i18n';

const reviewOfMechanicImporterActions = importerActions(
  'REVIEWOFMECHANIC_IMPORTER',
  selectors,
  ReviewOfMechanicService.import,
  fields,
  i18n('entities.reviewOfMechanic.importer.fileName'),
);

export default reviewOfMechanicImporterActions;