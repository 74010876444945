import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/mechanicResponseToClientRequest/importer/mechanicResponseToClientRequestImporterSelectors';
import MechanicResponseToClientRequestService from 'src/modules/mechanicResponseToClientRequest/mechanicResponseToClientRequestService';
import fields from 'src/modules/mechanicResponseToClientRequest/importer/mechanicResponseToClientRequestImporterFields';
import { i18n } from 'src/i18n';

const mechanicResponseToClientRequestImporterActions = importerActions(
  'MECHANICRESPONSETOCLIENTREQUEST_IMPORTER',
  selectors,
  MechanicResponseToClientRequestService.import,
  fields,
  i18n('entities.mechanicResponseToClientRequest.importer.fileName'),
);

export default mechanicResponseToClientRequestImporterActions;