import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import mechanicResponseToClientRequestEnumerators from 'src/modules/mechanicResponseToClientRequest/mechanicResponseToClientRequestEnumerators';

export default [
  {
    name: 'response',
    label: i18n('entities.mechanicResponseToClientRequest.fields.response'),
    schema: schemas.enumerator(
      i18n('entities.mechanicResponseToClientRequest.fields.response'),
      {
        "required": true,
        "options": mechanicResponseToClientRequestEnumerators.response
      },
    ),
  },
  {
    name: 'reason',
    label: i18n('entities.mechanicResponseToClientRequest.fields.reason'),
    schema: schemas.string(
      i18n('entities.mechanicResponseToClientRequest.fields.reason'),
      {
        "max": 380
      },
    ),
  },
  {
    name: 'responseToClientRequestId',
    label: i18n('entities.mechanicResponseToClientRequest.fields.responseToClientRequestId'),
    schema: schemas.relationToOne(
      i18n('entities.mechanicResponseToClientRequest.fields.responseToClientRequestId'),
      {},
    ),
  },
  {
    name: 'documents',
    label: i18n('entities.mechanicResponseToClientRequest.fields.documents'),
    schema: schemas.files(
      i18n('entities.mechanicResponseToClientRequest.fields.documents'),
      {},
    ),
  },
  {
    name: 'clientResponseToMechanicResponseOnClientRequest',
    label: i18n('entities.mechanicResponseToClientRequest.fields.clientResponseToMechanicResponseOnClientRequest'),
    schema: schemas.relationToMany(
      i18n('entities.mechanicResponseToClientRequest.fields.clientResponseToMechanicResponseOnClientRequest'),
      {},
    ),
  },
];