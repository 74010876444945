import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/servicesOffered/importer/servicesOfferedImporterSelectors';
import ServicesOfferedService from 'src/modules/servicesOffered/servicesOfferedService';
import fields from 'src/modules/servicesOffered/importer/servicesOfferedImporterFields';
import { i18n } from 'src/i18n';

const servicesOfferedImporterActions = importerActions(
  'SERVICESOFFERED_IMPORTER',
  selectors,
  ServicesOfferedService.import,
  fields,
  i18n('entities.servicesOffered.importer.fileName'),
);

export default servicesOfferedImporterActions;