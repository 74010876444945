import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import reviewOfMechanicEnumerators from 'src/modules/reviewOfMechanic/reviewOfMechanicEnumerators';

export default [
  {
    name: 'reviewOfMechanic',
    label: i18n('entities.reviewOfMechanic.fields.reviewOfMechanic'),
    schema: schemas.relationToOne(
      i18n('entities.reviewOfMechanic.fields.reviewOfMechanic'),
      {},
    ),
  },
  {
    name: 'comments',
    label: i18n('entities.reviewOfMechanic.fields.comments'),
    schema: schemas.string(
      i18n('entities.reviewOfMechanic.fields.comments'),
      {
        "max": 380,
        "required": true
      },
    ),
  },
  {
    name: 'mechanicReviewRating',
    label: i18n('entities.reviewOfMechanic.fields.mechanicReviewRating'),
    schema: schemas.enumerator(
      i18n('entities.reviewOfMechanic.fields.mechanicReviewRating'),
      {
        "required": true,
        "options": reviewOfMechanicEnumerators.mechanicReviewRating
      },
    ),
  },
  {
    name: 'byCustomer',
    label: i18n('entities.reviewOfMechanic.fields.byCustomer'),
    schema: schemas.relationToOne(
      i18n('entities.reviewOfMechanic.fields.byCustomer'),
      {
        "required": true
      },
    ),
  },
];