import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.convenienceFee.fields.id'),
  },
  {
    name: 'convenienceFeeName',
    label: i18n('entities.convenienceFee.fields.convenienceFeeName'),
  },
  {
    name: 'convenienceFeeDescription',
    label: i18n('entities.convenienceFee.fields.convenienceFeeDescription'),
  },
  {
    name: 'convenienceFeeServices',
    label: i18n('entities.convenienceFee.fields.convenienceFeeServices'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'convenienceFeeAmount',
    label: i18n('entities.convenienceFee.fields.convenienceFeeAmount'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.convenienceFee.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.convenienceFee.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
