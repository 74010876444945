import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/advertisements/importer/advertisementsImporterSelectors';
import AdvertisementsService from 'src/modules/advertisements/advertisementsService';
import fields from 'src/modules/advertisements/importer/advertisementsImporterFields';
import { i18n } from 'src/i18n';

const advertisementsImporterActions = importerActions(
  'ADVERTISEMENTS_IMPORTER',
  selectors,
  AdvertisementsService.import,
  fields,
  i18n('entities.advertisements.importer.fileName'),
);

export default advertisementsImporterActions;