import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/carMakes/importer/carMakesImporterSelectors';
import CarMakesService from 'src/modules/carMakes/carMakesService';
import fields from 'src/modules/carMakes/importer/carMakesImporterFields';
import { i18n } from 'src/i18n';

const carMakesImporterActions = importerActions(
  'CARMAKES_IMPORTER',
  selectors,
  CarMakesService.import,
  fields,
  i18n('entities.carMakes.importer.fileName'),
);

export default carMakesImporterActions;