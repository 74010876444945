import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.shopProduct.fields.id'),
  },
  {
    name: 'productImage',
    label: i18n('entities.shopProduct.fields.productImage'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'productName',
    label: i18n('entities.shopProduct.fields.productName'),
  },
  {
    name: 'productDescription',
    label: i18n('entities.shopProduct.fields.productDescription'),
  },
  {
    name: 'productPrice',
    label: i18n('entities.shopProduct.fields.productPrice'),
  },
  {
    name: 'productOfShop',
    label: i18n('entities.shopProduct.fields.productOfShop'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'productOfCategory',
    label: i18n('entities.shopProduct.fields.productOfCategory'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'productLocation',
    label: i18n('entities.shopProduct.fields.productLocation'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'productSponsorship',
    label: i18n('entities.shopProduct.fields.productSponsorship'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'productSponsorshipExpiryDate',
    label: i18n('entities.shopProduct.fields.productSponsorshipExpiryDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'productSponsorshipPriority',
    label: i18n('entities.shopProduct.fields.productSponsorshipPriority'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.shopProduct.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.shopProduct.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
