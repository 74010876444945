import list from 'src/modules/shopOwner/list/shopOwnerListReducers';
import form from 'src/modules/shopOwner/form/shopOwnerFormReducers';
import view from 'src/modules/shopOwner/view/shopOwnerViewReducers';
import destroy from 'src/modules/shopOwner/destroy/shopOwnerDestroyReducers';
import importerReducer from 'src/modules/shopOwner/importer/shopOwnerImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
