import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import advertisementsEnumerators from 'src/modules/advertisements/advertisementsEnumerators';
import moment from 'moment';

export default [
  {
    name: 'advertProduct',
    label: i18n('entities.advertisements.fields.advertProduct'),
    schema: schemas.relationToOne(
      i18n('entities.advertisements.fields.advertProduct'),
      {},
    ),
  },
  {
    name: 'advertBelongToShopOwner',
    label: i18n('entities.advertisements.fields.advertBelongToShopOwner'),
    schema: schemas.relationToOne(
      i18n('entities.advertisements.fields.advertBelongToShopOwner'),
      {},
    ),
  },
  {
    name: 'advertExpiry',
    label: i18n('entities.advertisements.fields.advertExpiry'),
    schema: schemas.datetime(
      i18n('entities.advertisements.fields.advertExpiry'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'advertStatus',
    label: i18n('entities.advertisements.fields.advertStatus'),
    schema: schemas.enumerator(
      i18n('entities.advertisements.fields.advertStatus'),
      {
        "options": advertisementsEnumerators.advertStatus
      },
    ),
  },
  {
    name: 'advertPackage',
    label: i18n('entities.advertisements.fields.advertPackage'),
    schema: schemas.relationToOne(
      i18n('entities.advertisements.fields.advertPackage'),
      {},
    ),
  },
  {
    name: 'advertPaymentTransactionCode',
    label: i18n('entities.advertisements.fields.advertPaymentTransactionCode'),
    schema: schemas.relationToOne(
      i18n('entities.advertisements.fields.advertPaymentTransactionCode'),
      {},
    ),
  },
];