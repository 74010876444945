import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.admin],
       allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.admin],
       allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [
          roles.admin, 
          roles.userMechanic, 
          roles.userCustomer
        ],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      preferredModeOfContactImport: {
        id: 'preferredModeOfContactImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      preferredModeOfContactCreate: {
        id: 'preferredModeOfContactCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      preferredModeOfContactEdit: {
        id: 'preferredModeOfContactEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      preferredModeOfContactDestroy: {
        id: 'preferredModeOfContactDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      preferredModeOfContactRead: {
        id: 'preferredModeOfContactRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      preferredModeOfContactAutocomplete: {
        id: 'preferredModeOfContactAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      carImport: {
        id: 'carImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      carCreate: {
        id: 'carCreate',
        allowedRoles: [
          roles.admin,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      carEdit: {
        id: 'carEdit',
        allowedRoles: [
          roles.admin,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      carDestroy: {
        id: 'carDestroy',
        allowedRoles: [
          roles.admin,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      carRead: {
        id: 'carRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      carAutocomplete: {
        id: 'carAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      locationImport: {
        id: 'locationImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      locationCreate: {
        id: 'locationCreate',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      locationEdit: {
        id: 'locationEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      locationDestroy: {
        id: 'locationDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      locationRead: {
        id: 'locationRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      locationAutocomplete: {
        id: 'locationAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      carModelImport: {
        id: 'carModelImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      carModelCreate: {
        id: 'carModelCreate',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      carModelEdit: {
        id: 'carModelEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      carModelDestroy: {
        id: 'carModelDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      carModelRead: {
        id: 'carModelRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      carModelAutocomplete: {
        id: 'carModelAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      carMakesImport: {
        id: 'carMakesImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      carMakesCreate: {
        id: 'carMakesCreate',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      carMakesEdit: {
        id: 'carMakesEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      carMakesDestroy: {
        id: 'carMakesDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      carMakesRead: {
        id: 'carMakesRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      carMakesAutocomplete: {
        id: 'carMakesAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      clientRequestToMechanicImport: {
        id: 'clientRequestToMechanicImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      clientRequestToMechanicCreate: {
        id: 'clientRequestToMechanicCreate',
        allowedRoles: [
          roles.admin,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      clientRequestToMechanicEdit: {
        id: 'clientRequestToMechanicEdit',
        allowedRoles: [
          roles.admin,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      clientRequestToMechanicDestroy: {
        id: 'clientRequestToMechanicDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      clientRequestToMechanicRead: {
        id: 'clientRequestToMechanicRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      clientRequestToMechanicAutocomplete: {
        id: 'clientRequestToMechanicAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      mechanicImport: {
        id: 'mechanicImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      mechanicCreate: {
        id: 'mechanicCreate',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      mechanicEdit: {
        id: 'mechanicEdit',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      mechanicDestroy: {
        id: 'mechanicDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      mechanicRead: {
        id: 'mechanicRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      mechanicAutocomplete: {
        id: 'mechanicAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      paymentMethodImport: {
        id: 'paymentMethodImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      paymentMethodCreate: {
        id: 'paymentMethodCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      paymentMethodEdit: {
        id: 'paymentMethodEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      paymentMethodDestroy: {
        id: 'paymentMethodDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      paymentMethodRead: {
        id: 'paymentMethodRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      paymentMethodAutocomplete: {
        id: 'paymentMethodAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      paymentImport: {
        id: 'paymentImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      paymentCreate: {
        id: 'paymentCreate',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      paymentEdit: {
        id: 'paymentEdit',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      paymentDestroy: {
        id: 'paymentDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      paymentRead: {
        id: 'paymentRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      paymentAutocomplete: {
        id: 'paymentAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      responseDocumentImport: {
        id: 'responseDocumentImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      responseDocumentCreate: {
        id: 'responseDocumentCreate',
        allowedRoles: [
          roles.admin,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.responseDocumentResponseFiles,
        ],
      },
      responseDocumentEdit: {
        id: 'responseDocumentEdit',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.responseDocumentResponseFiles,
        ],
      },
      responseDocumentDestroy: {
        id: 'responseDocumentDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.responseDocumentResponseFiles,
        ],
      },
      responseDocumentRead: {
        id: 'responseDocumentRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      responseDocumentAutocomplete: {
        id: 'responseDocumentAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      mechanicResponseToClientRequestImport: {
        id: 'mechanicResponseToClientRequestImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      mechanicResponseToClientRequestCreate: {
        id: 'mechanicResponseToClientRequestCreate',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.mechanicResponseToClientRequestDocuments,
        ],
      },
      mechanicResponseToClientRequestEdit: {
        id: 'mechanicResponseToClientRequestEdit',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.mechanicResponseToClientRequestDocuments,
        ],
      },
      mechanicResponseToClientRequestDestroy: {
        id: 'mechanicResponseToClientRequestDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.mechanicResponseToClientRequestDocuments,
        ],
      },
      mechanicResponseToClientRequestRead: {
        id: 'mechanicResponseToClientRequestRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      mechanicResponseToClientRequestAutocomplete: {
        id: 'mechanicResponseToClientRequestAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      clientsResponseToMechanicResponseImport: {
        id: 'clientsResponseToMechanicResponseImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      clientsResponseToMechanicResponseCreate: {
        id: 'clientsResponseToMechanicResponseCreate',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.clientsResponseToMechanicResponseDocuments,
        ],
      },
      clientsResponseToMechanicResponseEdit: {
        id: 'clientsResponseToMechanicResponseEdit',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.clientsResponseToMechanicResponseDocuments,
        ],
      },
      clientsResponseToMechanicResponseDestroy: {
        id: 'clientsResponseToMechanicResponseDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.clientsResponseToMechanicResponseDocuments,
        ],
      },
      clientsResponseToMechanicResponseRead: {
        id: 'clientsResponseToMechanicResponseRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      clientsResponseToMechanicResponseAutocomplete: {
        id: 'clientsResponseToMechanicResponseAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      garageImport: {
        id: 'garageImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      garageCreate: {
        id: 'garageCreate',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.garageGarageImages,
        ],
      },
      garageEdit: {
        id: 'garageEdit',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.garageGarageImages,
        ],
      },
      garageDestroy: {
        id: 'garageDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.garageGarageImages,
        ],
      },
      garageRead: {
        id: 'garageRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      garageAutocomplete: {
        id: 'garageAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      reviewsOfGaragesImport: {
        id: 'reviewsOfGaragesImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      reviewsOfGaragesCreate: {
        id: 'reviewsOfGaragesCreate',
        allowedRoles: [
          roles.admin,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      reviewsOfGaragesEdit: {
        id: 'reviewsOfGaragesEdit',
        allowedRoles: [
          roles.admin,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      reviewsOfGaragesDestroy: {
        id: 'reviewsOfGaragesDestroy',
        allowedRoles: [
          roles.admin,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      reviewsOfGaragesRead: {
        id: 'reviewsOfGaragesRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      reviewsOfGaragesAutocomplete: {
        id: 'reviewsOfGaragesAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      servicesOfferedImport: {
        id: 'servicesOfferedImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      servicesOfferedCreate: {
        id: 'servicesOfferedCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      servicesOfferedEdit: {
        id: 'servicesOfferedEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      servicesOfferedDestroy: {
        id: 'servicesOfferedDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      servicesOfferedRead: {
        id: 'servicesOfferedRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      servicesOfferedAutocomplete: {
        id: 'servicesOfferedAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      reviewOfMechanicImport: {
        id: 'reviewOfMechanicImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      reviewOfMechanicCreate: {
        id: 'reviewOfMechanicCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      reviewOfMechanicEdit: {
        id: 'reviewOfMechanicEdit',
        allowedRoles: [
          roles.admin,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      reviewOfMechanicDestroy: {
        id: 'reviewOfMechanicDestroy',
        allowedRoles: [
          roles.admin,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      reviewOfMechanicRead: {
        id: 'reviewOfMechanicRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      reviewOfMechanicAutocomplete: {
        id: 'reviewOfMechanicAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      identificationDocumentImport: {
        id: 'identificationDocumentImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      identificationDocumentCreate: {
        id: 'identificationDocumentCreate',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.identificationDocumentIdImages,
        ],
      },
      identificationDocumentEdit: {
        id: 'identificationDocumentEdit',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.identificationDocumentIdImages,
        ],
      },
      identificationDocumentDestroy: {
        id: 'identificationDocumentDestroy',
        allowedRoles: [
          roles.admin
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.identificationDocumentIdImages,
        ],
      },
      identificationDocumentRead: {
        id: 'identificationDocumentRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      identificationDocumentAutocomplete: {
        id: 'identificationDocumentAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      certificateOfRegistrationImport: {
        id: 'certificateOfRegistrationImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      certificateOfRegistrationCreate: {
        id: 'certificateOfRegistrationCreate',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.certificateOfRegistrationGarageCertificateImages,
        ],
      },
      certificateOfRegistrationEdit: {
        id: 'certificateOfRegistrationEdit',
        allowedRoles: [
          roles.admin,
          roles.userMechanic
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.certificateOfRegistrationGarageCertificateImages,
        ],
      },
      certificateOfRegistrationDestroy: {
        id: 'certificateOfRegistrationDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.certificateOfRegistrationGarageCertificateImages,
        ],
      },
      certificateOfRegistrationRead: {
        id: 'certificateOfRegistrationRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      certificateOfRegistrationAutocomplete: {
        id: 'certificateOfRegistrationAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      clientOrderImport: {
        id: 'clientOrderImport',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      clientOrderCreate: {
        id: 'clientOrderCreate',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      clientOrderEdit: {
        id: 'clientOrderEdit',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      clientOrderDestroy: {
        id: 'clientOrderDestroy',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      clientOrderRead: {
        id: 'clientOrderRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      clientOrderAutocomplete: {
        id: 'clientOrderAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      convenienceFeeImport: {
        id: 'convenienceFeeImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      convenienceFeeCreate: {
        id: 'convenienceFeeCreate',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      convenienceFeeEdit: {
        id: 'convenienceFeeEdit',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      convenienceFeeDestroy: {
        id: 'convenienceFeeDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      convenienceFeeRead: {
        id: 'convenienceFeeRead',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      convenienceFeeAutocomplete: {
        id: 'convenienceFeeAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userMechanic,
          roles.userCustomer
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      shopOwnerImport: {
        id: 'shopOwnerImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      shopOwnerCreate: {
        id: 'shopOwnerCreate',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopOwnerEdit: {
        id: 'shopOwnerEdit',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopOwnerDestroy: {
        id: 'shopOwnerDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopOwnerRead: {
        id: 'shopOwnerRead',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      shopOwnerAutocomplete: {
        id: 'shopOwnerAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      shopProductImport: {
        id: 'shopProductImport',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      shopProductCreate: {
        id: 'shopProductCreate',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.shopProductImages,
        ],
      },
      shopProductEdit: {
        id: 'shopProductEdit',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.shopProductImages,
        ],
      },
      shopProductDestroy: {
        id: 'shopProductDestroy',
        allowedRoles: [
          roles.admin,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.shopProductImages,
        ],
      },
      shopProductRead: {
        id: 'shopProductRead',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      shopProductAutocomplete: {
        id: 'shopProductAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      shopImport: {
        id: 'shopImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      shopCreate: {
        id: 'shopCreate',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopEdit: {
        id: 'shopEdit',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopDestroy: {
        id: 'shopDestroy',
        allowedRoles: [
          roles.admin,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopRead: {
        id: 'shopRead',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      shopAutocomplete: {
        id: 'shopAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      shopProductCategoryImport: {
        id: 'shopProductCategoryImport',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      shopProductCategoryCreate: {
        id: 'shopProductCategoryCreate',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopProductCategoryEdit: {
        id: 'shopProductCategoryEdit',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopProductCategoryDestroy: {
        id: 'shopProductCategoryDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopProductCategoryRead: {
        id: 'shopProductCategoryRead',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      shopProductCategoryAutocomplete: {
        id: 'shopProductCategoryAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      advertisementsImport: {
        id: 'advertisementsImport',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      advertisementsCreate: {
        id: 'advertisementsCreate',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      advertisementsEdit: {
        id: 'advertisementsEdit',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      advertisementsDestroy: {
        id: 'advertisementsDestroy',
        allowedRoles: [roles.admin, roles.userShopOwner],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      advertisementsRead: {
        id: 'advertisementsRead',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      advertisementsAutocomplete: {
        id: 'advertisementsAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      advertPaymentImport: {
        id: 'advertPaymentImport',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      advertPaymentCreate: {
        id: 'advertPaymentCreate',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      advertPaymentEdit: {
        id: 'advertPaymentEdit',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      advertPaymentDestroy: {
        id: 'advertPaymentDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      advertPaymentRead: {
        id: 'advertPaymentRead',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      advertPaymentAutocomplete: {
        id: 'advertPaymentAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      advertPackageImport: {
        id: 'advertPackageImport',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      advertPackageCreate: {
        id: 'advertPackageCreate',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      advertPackageEdit: {
        id: 'advertPackageEdit',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      advertPackageDestroy: {
        id: 'advertPackageDestroy',
        allowedRoles: [roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      advertPackageRead: {
        id: 'advertPackageRead',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      advertPackageAutocomplete: {
        id: 'advertPackageAutocomplete',
        allowedRoles: [
          roles.admin,
          roles.userCustomer,
          roles.userMechanic,
          roles.userShopOwner
        ],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      adPackageOrderImport: {
        id: 'adPackageOrderImport',
        allowedRoles: [roles.admin,roles.userCustomer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      adPackageOrderCreate: {
        id: 'adPackageOrderCreate',
        allowedRoles: [roles.admin,roles.userCustomer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      adPackageOrderEdit: {
        id: 'adPackageOrderEdit',
        allowedRoles: [roles.admin,roles.userCustomer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      adPackageOrderDestroy: {
        id: 'adPackageOrderDestroy',
        allowedRoles: [roles.admin,roles.userCustomer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      adPackageOrderRead: {
        id: 'adPackageOrderRead',
        allowedRoles: [roles.admin,roles.userCustomer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      adPackageOrderAutocomplete: {
        id: 'adPackageOrderAutocomplete',
        allowedRoles: [roles.admin,roles.userCustomer],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
