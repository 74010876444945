/**
 * List of Plans
 */
class Plans {
  static get values() {
    return {
      free: 'free',
      growth: 'growth',
      enterprise: 'enterprise',
    };
  }
}

export default Plans;
