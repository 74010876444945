import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import clientOrderEnumerators from 'src/modules/clientOrder/clientOrderEnumerators';

export default [
  {
    name: 'orderBillingFirstName',
    label: i18n('entities.clientOrder.fields.orderBillingFirstName'),
    schema: schemas.string(
      i18n('entities.clientOrder.fields.orderBillingFirstName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'orderBillingLastName',
    label: i18n('entities.clientOrder.fields.orderBillingLastName'),
    schema: schemas.string(
      i18n('entities.clientOrder.fields.orderBillingLastName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'orderClientRequestIds',
    label: i18n('entities.clientOrder.fields.orderClientRequestIds'),
    schema: schemas.relationToMany(
      i18n('entities.clientOrder.fields.orderClientRequestIds'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'orderOfLoggedInUserId',
    label: i18n('entities.clientOrder.fields.orderOfLoggedInUserId'),
    schema: schemas.relationToOne(
      i18n('entities.clientOrder.fields.orderOfLoggedInUserId'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'orderBillingPhoneNumber',
    label: i18n('entities.clientOrder.fields.orderBillingPhoneNumber'),
    schema: schemas.string(
      i18n('entities.clientOrder.fields.orderBillingPhoneNumber'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'orderBillingEmail',
    label: i18n('entities.clientOrder.fields.orderBillingEmail'),
    schema: schemas.string(
      i18n('entities.clientOrder.fields.orderBillingEmail'),
      {},
    ),
  },
  {
    name: 'orderBillingCompany',
    label: i18n('entities.clientOrder.fields.orderBillingCompany'),
    schema: schemas.string(
      i18n('entities.clientOrder.fields.orderBillingCompany'),
      {},
    ),
  },
  {
    name: 'orderPaymentId',
    label: i18n('entities.clientOrder.fields.orderPaymentId'),
    schema: schemas.relationToOne(
      i18n('entities.clientOrder.fields.orderPaymentId'),
      {},
    ),
  },
  {
    name: 'orderStatus',
    label: i18n('entities.clientOrder.fields.orderStatus'),
    schema: schemas.enumerator(
      i18n('entities.clientOrder.fields.orderStatus'),
      {
        "options": clientOrderEnumerators.orderStatus
      },
    ),
  },
  {
    name: 'orderTotal',
    label: i18n('entities.clientOrder.fields.orderTotal'),
    schema: schemas.integer(
      i18n('entities.clientOrder.fields.orderTotal'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'orderBillingAdditionalInfo',
    label: i18n('entities.clientOrder.fields.orderBillingAdditionalInfo'),
    schema: schemas.string(
      i18n('entities.clientOrder.fields.orderBillingAdditionalInfo'),
      {},
    ),
  },
];