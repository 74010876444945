import ShopProductService from 'src/modules/shopProduct/shopProductService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'SHOPPRODUCT_VIEW';

const shopProductViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: shopProductViewActions.FIND_STARTED,
      });

      const record = await ShopProductService.find(id);

      dispatch({
        type: shopProductViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: shopProductViewActions.FIND_ERROR,
      });

      getHistory().push('/shop-product');
    }
  },
};

export default shopProductViewActions;
