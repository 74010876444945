import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.car.fields.id'),
  },
  {
    name: 'carRegNumber',
    label: i18n('entities.car.fields.carRegNumber'),
  },
  {
    name: 'carOwnedByUser',
    label: i18n('entities.car.fields.carOwnedByUser'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'carLocatedAt',
    label: i18n('entities.car.fields.carLocatedAt'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'carMake',
    label: i18n('entities.car.fields.carMake'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'carModel',
    label: i18n('entities.car.fields.carModel'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'carsAdditionalInfo',
    label: i18n('entities.car.fields.carsAdditionalInfo'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.car.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.car.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
