import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.clientOrder.fields.id'),
  },
  {
    name: 'orderBillingFirstName',
    label: i18n('entities.clientOrder.fields.orderBillingFirstName'),
  },
  {
    name: 'orderBillingLastName',
    label: i18n('entities.clientOrder.fields.orderBillingLastName'),
  },
  {
    name: 'orderClientRequestIds',
    label: i18n('entities.clientOrder.fields.orderClientRequestIds'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'orderOfLoggedInUserId',
    label: i18n('entities.clientOrder.fields.orderOfLoggedInUserId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'orderBillingPhoneNumber',
    label: i18n('entities.clientOrder.fields.orderBillingPhoneNumber'),
  },
  {
    name: 'orderBillingEmail',
    label: i18n('entities.clientOrder.fields.orderBillingEmail'),
  },
  {
    name: 'orderBillingCompany',
    label: i18n('entities.clientOrder.fields.orderBillingCompany'),
  },
  {
    name: 'orderPaymentId',
    label: i18n('entities.clientOrder.fields.orderPaymentId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'orderStatus',
    label: i18n('entities.clientOrder.fields.orderStatus'),
  },
  {
    name: 'orderTotal',
    label: i18n('entities.clientOrder.fields.orderTotal'),
  },
  {
    name: 'orderBillingAdditionalInfo',
    label: i18n('entities.clientOrder.fields.orderBillingAdditionalInfo'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.clientOrder.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.clientOrder.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
