import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import advertPaymentEnumerators from 'src/modules/advertPayment/advertPaymentEnumerators';

export default [
  {
    name: 'advertPaymentTransactionCode',
    label: i18n('entities.advertPayment.fields.advertPaymentTransactionCode'),
    schema: schemas.string(
      i18n('entities.advertPayment.fields.advertPaymentTransactionCode'),
      {},
    ),
  },
  {
    name: 'advertPaymentStatus',
    label: i18n('entities.advertPayment.fields.advertPaymentStatus'),
    schema: schemas.enumerator(
      i18n('entities.advertPayment.fields.advertPaymentStatus'),
      {
        "options": advertPaymentEnumerators.advertPaymentStatus
      },
    ),
  },
  {
    name: 'advertPaymentAmount',
    label: i18n('entities.advertPayment.fields.advertPaymentAmount'),
    schema: schemas.integer(
      i18n('entities.advertPayment.fields.advertPaymentAmount'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'advertPaymentOfAdvertisement',
    label: i18n('entities.advertPayment.fields.advertPaymentOfAdvertisement'),
    schema: schemas.relationToMany(
      i18n('entities.advertPayment.fields.advertPaymentOfAdvertisement'),
      {},
    ),
  },
  {
    name: 'advertPaymentOrderDetails',
    label: i18n('entities.advertPayment.fields.advertPaymentOrderDetails'),
    schema: schemas.relationToMany(
      i18n('entities.advertPayment.fields.advertPaymentOrderDetails'),
      {},
    ),
  },
];