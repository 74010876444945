import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.paymentMethod.fields.id'),
  },
  {
    name: 'paymentMethodName',
    label: i18n('entities.paymentMethod.fields.paymentMethodName'),
  },
  {
    name: 'paymentMethodDescription',
    label: i18n('entities.paymentMethod.fields.paymentMethodDescription'),
  },
  {
    name: 'paymentMethodStatus',
    label: i18n('entities.paymentMethod.fields.paymentMethodStatus'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.paymentMethod.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.paymentMethod.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
