import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/preferredModeOfContact/importer/preferredModeOfContactImporterSelectors';
import PreferredModeOfContactService from 'src/modules/preferredModeOfContact/preferredModeOfContactService';
import fields from 'src/modules/preferredModeOfContact/importer/preferredModeOfContactImporterFields';
import { i18n } from 'src/i18n';

const preferredModeOfContactImporterActions = importerActions(
  'PREFERREDMODEOFCONTACT_IMPORTER',
  selectors,
  PreferredModeOfContactService.import,
  fields,
  i18n('entities.preferredModeOfContact.importer.fileName'),
);

export default preferredModeOfContactImporterActions;