import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/adPackageOrder/importer/adPackageOrderImporterSelectors';
import AdPackageOrderService from 'src/modules/adPackageOrder/adPackageOrderService';
import fields from 'src/modules/adPackageOrder/importer/adPackageOrderImporterFields';
import { i18n } from 'src/i18n';

const adPackageOrderImporterActions = importerActions(
  'ADPACKAGEORDER_IMPORTER',
  selectors,
  AdPackageOrderService.import,
  fields,
  i18n('entities.adPackageOrder.importer.fileName'),
);

export default adPackageOrderImporterActions;