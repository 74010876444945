import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.advertisements.fields.id'),
  },
  {
    name: 'advertProduct',
    label: i18n('entities.advertisements.fields.advertProduct'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'advertBelongToShopOwner',
    label: i18n('entities.advertisements.fields.advertBelongToShopOwner'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'advertExpiry',
    label: i18n('entities.advertisements.fields.advertExpiry'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'advertStatus',
    label: i18n('entities.advertisements.fields.advertStatus'),
  },
  {
    name: 'advertPackage',
    label: i18n('entities.advertisements.fields.advertPackage'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'advertPaymentTransactionCode',
    label: i18n('entities.advertisements.fields.advertPaymentTransactionCode'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.advertisements.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.advertisements.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
