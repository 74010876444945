import list from 'src/modules/advertPackage/list/advertPackageListReducers';
import form from 'src/modules/advertPackage/form/advertPackageFormReducers';
import view from 'src/modules/advertPackage/view/advertPackageViewReducers';
import destroy from 'src/modules/advertPackage/destroy/advertPackageDestroyReducers';
import importerReducer from 'src/modules/advertPackage/importer/advertPackageImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
