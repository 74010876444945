import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'isAlsoUser',
    label: i18n('entities.shopOwner.fields.isAlsoUser'),
    schema: schemas.relationToOne(
      i18n('entities.shopOwner.fields.isAlsoUser'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'shops',
    label: i18n('entities.shopOwner.fields.shops'),
    schema: schemas.relationToMany(
      i18n('entities.shopOwner.fields.shops'),
      {},
    ),
  },
  {
    name: 'shopOwnersAdverts',
    label: i18n('entities.shopOwner.fields.shopOwnersAdverts'),
    schema: schemas.relationToMany(
      i18n('entities.shopOwner.fields.shopOwnersAdverts'),
      {},
    ),
  },
  {
    name: 'shopOwnersPackageSubscription',
    label: i18n('entities.shopOwner.fields.shopOwnersPackageSubscription'),
    schema: schemas.relationToOne(
      i18n('entities.shopOwner.fields.shopOwnersPackageSubscription'),
      {},
    ),
  },
];