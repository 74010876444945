import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import moment from 'moment';

export default [
  {
    name: 'productImage',
    label: i18n('entities.shopProduct.fields.productImage'),
    schema: schemas.images(
      i18n('entities.shopProduct.fields.productImage'),
      {
        "max": 5,
        "min": 0
      },
    ),
  },
  {
    name: 'productName',
    label: i18n('entities.shopProduct.fields.productName'),
    schema: schemas.string(
      i18n('entities.shopProduct.fields.productName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'productDescription',
    label: i18n('entities.shopProduct.fields.productDescription'),
    schema: schemas.string(
      i18n('entities.shopProduct.fields.productDescription'),
      {},
    ),
  },
  {
    name: 'productPrice',
    label: i18n('entities.shopProduct.fields.productPrice'),
    schema: schemas.integer(
      i18n('entities.shopProduct.fields.productPrice'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'productOfShop',
    label: i18n('entities.shopProduct.fields.productOfShop'),
    schema: schemas.relationToOne(
      i18n('entities.shopProduct.fields.productOfShop'),
      {},
    ),
  },
  {
    name: 'productOfCategory',
    label: i18n('entities.shopProduct.fields.productOfCategory'),
    schema: schemas.relationToOne(
      i18n('entities.shopProduct.fields.productOfCategory'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'productLocation',
    label: i18n('entities.shopProduct.fields.productLocation'),
    schema: schemas.relationToOne(
      i18n('entities.shopProduct.fields.productLocation'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'productSponsorship',
    label: i18n('entities.shopProduct.fields.productSponsorship'),
    schema: schemas.relationToMany(
      i18n('entities.shopProduct.fields.productSponsorship'),
      {},
    ),
  },
  {
    name: 'productSponsorshipExpiryDate',
    label: i18n('entities.shopProduct.fields.productSponsorshipExpiryDate'),
    schema: schemas.datetime(
      i18n('entities.shopProduct.fields.productSponsorshipExpiryDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'productSponsorshipPriority',
    label: i18n('entities.shopProduct.fields.productSponsorshipPriority'),
    schema: schemas.integer(
      i18n('entities.shopProduct.fields.productSponsorshipPriority'),
      {},
    ),
  },
];