import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'garageName',
    label: i18n('entities.garage.fields.garageName'),
    schema: schemas.string(
      i18n('entities.garage.fields.garageName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'garageOwnedByMechanic',
    label: i18n('entities.garage.fields.garageOwnedByMechanic'),
    schema: schemas.relationToOne(
      i18n('entities.garage.fields.garageOwnedByMechanic'),
      {},
    ),
  },
  {
    name: 'garageDescription',
    label: i18n('entities.garage.fields.garageDescription'),
    schema: schemas.string(
      i18n('entities.garage.fields.garageDescription'),
      {
        "max": 380
      },
    ),
  },
  {
    name: 'garageLocation',
    label: i18n('entities.garage.fields.garageLocation'),
    schema: schemas.relationToOne(
      i18n('entities.garage.fields.garageLocation'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'garageImages',
    label: i18n('entities.garage.fields.garageImages'),
    schema: schemas.images(
      i18n('entities.garage.fields.garageImages'),
      {
        "max": 3
      },
    ),
  },
  {
    name: 'garageCertificateOfRegistration',
    label: i18n('entities.garage.fields.garageCertificateOfRegistration'),
    schema: schemas.relationToOne(
      i18n('entities.garage.fields.garageCertificateOfRegistration'),
      {},
    ),
  },
  {
    name: 'garageReview',
    label: i18n('entities.garage.fields.garageReview'),
    schema: schemas.relationToMany(
      i18n('entities.garage.fields.garageReview'),
      {},
    ),
  },
];