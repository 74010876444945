import list from 'src/modules/identificationDocument/list/identificationDocumentListReducers';
import form from 'src/modules/identificationDocument/form/identificationDocumentFormReducers';
import view from 'src/modules/identificationDocument/view/identificationDocumentViewReducers';
import destroy from 'src/modules/identificationDocument/destroy/identificationDocumentDestroyReducers';
import importerReducer from 'src/modules/identificationDocument/importer/identificationDocumentImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
