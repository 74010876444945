import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'idNumber',
    label: i18n('entities.identificationDocument.fields.idNumber'),
    schema: schemas.string(
      i18n('entities.identificationDocument.fields.idNumber'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'idImages',
    label: i18n('entities.identificationDocument.fields.idImages'),
    schema: schemas.images(
      i18n('entities.identificationDocument.fields.idImages'),
      {
        "required": true,
        "max": 3,
        "min": 1
      },
    ),
  },
  {
    name: 'idDetails',
    label: i18n('entities.identificationDocument.fields.idDetails'),
    schema: schemas.string(
      i18n('entities.identificationDocument.fields.idDetails'),
      {
        "max": 380
      },
    ),
  },
];