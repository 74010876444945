import list from 'src/modules/advertPayment/list/advertPaymentListReducers';
import form from 'src/modules/advertPayment/form/advertPaymentFormReducers';
import view from 'src/modules/advertPayment/view/advertPaymentViewReducers';
import destroy from 'src/modules/advertPayment/destroy/advertPaymentDestroyReducers';
import importerReducer from 'src/modules/advertPayment/importer/advertPaymentImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
