import list from 'src/modules/carMakes/list/carMakesListReducers';
import form from 'src/modules/carMakes/form/carMakesFormReducers';
import view from 'src/modules/carMakes/view/carMakesViewReducers';
import destroy from 'src/modules/carMakes/destroy/carMakesDestroyReducers';
import importerReducer from 'src/modules/carMakes/importer/carMakesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
