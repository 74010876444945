const advertisementsEnumerators = {
  advertStatus: [
    'Active',
    'Suspended',
    'Paused',
    'Expired',
  ],
};

export default advertisementsEnumerators;
