import list from 'src/modules/mechanic/list/mechanicListReducers';
import form from 'src/modules/mechanic/form/mechanicFormReducers';
import view from 'src/modules/mechanic/view/mechanicViewReducers';
import destroy from 'src/modules/mechanic/destroy/mechanicDestroyReducers';
import importerReducer from 'src/modules/mechanic/importer/mechanicImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
