import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.advertPayment.fields.id'),
  },
  {
    name: 'advertPaymentTransactionCode',
    label: i18n('entities.advertPayment.fields.advertPaymentTransactionCode'),
  },
  {
    name: 'advertPaymentStatus',
    label: i18n('entities.advertPayment.fields.advertPaymentStatus'),
  },
  {
    name: 'advertPaymentAmount',
    label: i18n('entities.advertPayment.fields.advertPaymentAmount'),
  },
  {
    name: 'advertPaymentOfAdvertisement',
    label: i18n('entities.advertPayment.fields.advertPaymentOfAdvertisement'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'advertPaymentOrderDetails',
    label: i18n('entities.advertPayment.fields.advertPaymentOrderDetails'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.advertPayment.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.advertPayment.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
