import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import paymentMethodEnumerators from 'src/modules/paymentMethod/paymentMethodEnumerators';

export default [
  {
    name: 'paymentMethodName',
    label: i18n('entities.paymentMethod.fields.paymentMethodName'),
    schema: schemas.string(
      i18n('entities.paymentMethod.fields.paymentMethodName'),
      {
        "required": true,
        "min": 2
      },
    ),
  },
  {
    name: 'paymentMethodDescription',
    label: i18n('entities.paymentMethod.fields.paymentMethodDescription'),
    schema: schemas.string(
      i18n('entities.paymentMethod.fields.paymentMethodDescription'),
      {},
    ),
  },
  {
    name: 'paymentMethodStatus',
    label: i18n('entities.paymentMethod.fields.paymentMethodStatus'),
    schema: schemas.enumerator(
      i18n('entities.paymentMethod.fields.paymentMethodStatus'),
      {
        "required": true,
        "options": paymentMethodEnumerators.paymentMethodStatus
      },
    ),
  },
];