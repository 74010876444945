import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import reviewsOfGaragesEnumerators from 'src/modules/reviewsOfGarages/reviewsOfGaragesEnumerators';

export default [
  {
    name: 'reviewOfGarage',
    label: i18n('entities.reviewsOfGarages.fields.reviewOfGarage'),
    schema: schemas.relationToOne(
      i18n('entities.reviewsOfGarages.fields.reviewOfGarage'),
      {},
    ),
  },
  {
    name: 'garageReviewRating',
    label: i18n('entities.reviewsOfGarages.fields.garageReviewRating'),
    schema: schemas.enumerator(
      i18n('entities.reviewsOfGarages.fields.garageReviewRating'),
      {
        "options": reviewsOfGaragesEnumerators.garageReviewRating
      },
    ),
  },
  {
    name: 'comments',
    label: i18n('entities.reviewsOfGarages.fields.comments'),
    schema: schemas.string(
      i18n('entities.reviewsOfGarages.fields.comments'),
      {
        "max": 380,
        "required": true
      },
    ),
  },
  {
    name: 'byCustomer',
    label: i18n('entities.reviewsOfGarages.fields.byCustomer'),
    schema: schemas.relationToOne(
      i18n('entities.reviewsOfGarages.fields.byCustomer'),
      {
        "required": true
      },
    ),
  },
];