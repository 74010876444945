import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'mechanicUserId',
    label: i18n('entities.mechanic.fields.mechanicUserId'),
    schema: schemas.relationToOne(
      i18n('entities.mechanic.fields.mechanicUserId'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'areaOfOperation',
    label: i18n('entities.mechanic.fields.areaOfOperation'),
    schema: schemas.relationToOne(
      i18n('entities.mechanic.fields.areaOfOperation'),
      {},
    ),
  },
  {
    name: 'mechanicsIdNo',
    label: i18n('entities.mechanic.fields.mechanicsIdNo'),
    schema: schemas.string(
      i18n('entities.mechanic.fields.mechanicsIdNo'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'mechanicIdentificationDocument',
    label: i18n('entities.mechanic.fields.mechanicIdentificationDocument'),
    schema: schemas.relationToOne(
      i18n('entities.mechanic.fields.mechanicIdentificationDocument'),
      {},
    ),
  },
  {
    name: 'enterCertificationOfRegistrationNo',
    label: i18n('entities.mechanic.fields.enterCertificationOfRegistrationNo'),
    schema: schemas.string(
      i18n('entities.mechanic.fields.enterCertificationOfRegistrationNo'),
      {},
    ),
  },
  {
    name: 'mechanicReviews',
    label: i18n('entities.mechanic.fields.mechanicReviews'),
    schema: schemas.relationToMany(
      i18n('entities.mechanic.fields.mechanicReviews'),
      {},
    ),
  },
  {
    name: 'mechanicsGarages',
    label: i18n('entities.mechanic.fields.mechanicsGarages'),
    schema: schemas.relationToMany(
      i18n('entities.mechanic.fields.mechanicsGarages'),
      {},
    ),
  },
  {
    name: 'mechanicServiceSpecialities',
    label: i18n('entities.mechanic.fields.mechanicServiceSpecialities'),
    schema: schemas.relationToMany(
      i18n('entities.mechanic.fields.mechanicServiceSpecialities'),
      {},
    ),
  },
  {
    name: 'preferredModeOfPayment',
    label: i18n('entities.mechanic.fields.preferredModeOfPayment'),
    schema: schemas.relationToOne(
      i18n('entities.mechanic.fields.preferredModeOfPayment'),
      {},
    ),
  },
];