import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/clientOrder/importer/clientOrderImporterSelectors';
import ClientOrderService from 'src/modules/clientOrder/clientOrderService';
import fields from 'src/modules/clientOrder/importer/clientOrderImporterFields';
import { i18n } from 'src/i18n';

const clientOrderImporterActions = importerActions(
  'CLIENTORDER_IMPORTER',
  selectors,
  ClientOrderService.import,
  fields,
  i18n('entities.clientOrder.importer.fileName'),
);

export default clientOrderImporterActions;