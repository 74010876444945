import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.clientRequestToMechanic.fields.id'),
  },
  {
    name: 'requestForCar',
    label: i18n('entities.clientRequestToMechanic.fields.requestForCar'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'requestDetail',
    label: i18n('entities.clientRequestToMechanic.fields.requestDetail'),
  },
  {
    name: 'affects',
    label: i18n('entities.clientRequestToMechanic.fields.affects'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'status',
    label: i18n('entities.clientRequestToMechanic.fields.status'),
  },
  {
    name: 'mechanicId',
    label: i18n('entities.clientRequestToMechanic.fields.mechanicId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'mechanicResponse',
    label: i18n('entities.clientRequestToMechanic.fields.mechanicResponse'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'dateFulfilled',
    label: i18n('entities.clientRequestToMechanic.fields.dateFulfilled'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.clientRequestToMechanic.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.clientRequestToMechanic.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
