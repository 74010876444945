import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/carModel/importer/carModelImporterSelectors';
import CarModelService from 'src/modules/carModel/carModelService';
import fields from 'src/modules/carModel/importer/carModelImporterFields';
import { i18n } from 'src/i18n';

const carModelImporterActions = importerActions(
  'CARMODEL_IMPORTER',
  selectors,
  CarModelService.import,
  fields,
  i18n('entities.carModel.importer.fileName'),
);

export default carModelImporterActions;