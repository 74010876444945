const adPackageOrderEnumerators = {
  adOrderStatus: [
    'PENDING',
    'CANCELLED',
    'PAID',
    'ARCHIVED',
    'REFUNDED',
  ],
};

export default adPackageOrderEnumerators;
