import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/advertPackage/importer/advertPackageImporterSelectors';
import AdvertPackageService from 'src/modules/advertPackage/advertPackageService';
import fields from 'src/modules/advertPackage/importer/advertPackageImporterFields';
import { i18n } from 'src/i18n';

const advertPackageImporterActions = importerActions(
  'ADVERTPACKAGE_IMPORTER',
  selectors,
  AdvertPackageService.import,
  fields,
  i18n('entities.advertPackage.importer.fileName'),
);

export default advertPackageImporterActions;