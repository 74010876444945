const clientOrderEnumerators = {
  orderStatus: [
    'PENDING',
    'CANCELLED',
    'PAIDCONVENIENCE',
    'PAID',
    'ARCHIVED',
    'REFUNDED',
  ],
};

export default clientOrderEnumerators;
