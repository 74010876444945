import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.mechanic.fields.id'),
  },
  {
    name: 'mechanicUserId',
    label: i18n('entities.mechanic.fields.mechanicUserId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'areaOfOperation',
    label: i18n('entities.mechanic.fields.areaOfOperation'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'mechanicsIdNo',
    label: i18n('entities.mechanic.fields.mechanicsIdNo'),
  },
  {
    name: 'mechanicIdentificationDocument',
    label: i18n('entities.mechanic.fields.mechanicIdentificationDocument'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'enterCertificationOfRegistrationNo',
    label: i18n('entities.mechanic.fields.enterCertificationOfRegistrationNo'),
  },
  {
    name: 'mechanicReviews',
    label: i18n('entities.mechanic.fields.mechanicReviews'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'mechanicsGarages',
    label: i18n('entities.mechanic.fields.mechanicsGarages'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'mechanicServiceSpecialities',
    label: i18n('entities.mechanic.fields.mechanicServiceSpecialities'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'preferredModeOfPayment',
    label: i18n('entities.mechanic.fields.preferredModeOfPayment'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.mechanic.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.mechanic.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
