import list from 'src/modules/reviewsOfGarages/list/reviewsOfGaragesListReducers';
import form from 'src/modules/reviewsOfGarages/form/reviewsOfGaragesFormReducers';
import view from 'src/modules/reviewsOfGarages/view/reviewsOfGaragesViewReducers';
import destroy from 'src/modules/reviewsOfGarages/destroy/reviewsOfGaragesDestroyReducers';
import importerReducer from 'src/modules/reviewsOfGarages/importer/reviewsOfGaragesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
