import list from 'src/modules/advertisements/list/advertisementsListReducers';
import form from 'src/modules/advertisements/form/advertisementsFormReducers';
import view from 'src/modules/advertisements/view/advertisementsViewReducers';
import destroy from 'src/modules/advertisements/destroy/advertisementsDestroyReducers';
import importerReducer from 'src/modules/advertisements/importer/advertisementsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
