import list from 'src/modules/mechanicResponseToClientRequest/list/mechanicResponseToClientRequestListReducers';
import form from 'src/modules/mechanicResponseToClientRequest/form/mechanicResponseToClientRequestFormReducers';
import view from 'src/modules/mechanicResponseToClientRequest/view/mechanicResponseToClientRequestViewReducers';
import destroy from 'src/modules/mechanicResponseToClientRequest/destroy/mechanicResponseToClientRequestDestroyReducers';
import importerReducer from 'src/modules/mechanicResponseToClientRequest/importer/mechanicResponseToClientRequestImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
