const clientRequestToMechanicEnumerators = {
  status: [
    'Active',
    'Inactive',
    'Cancelled',
    'Disabled',
  ],
};

export default clientRequestToMechanicEnumerators;
