import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import paymentEnumerators from 'src/modules/payment/paymentEnumerators';

export default [
  {
    name: 'paymentOfUser',
    label: i18n('entities.payment.fields.paymentOfUser'),
    schema: schemas.relationToOne(
      i18n('entities.payment.fields.paymentOfUser'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'paymentAmount',
    label: i18n('entities.payment.fields.paymentAmount'),
    schema: schemas.integer(
      i18n('entities.payment.fields.paymentAmount'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'status',
    label: i18n('entities.payment.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.payment.fields.status'),
      {
        "required": true,
        "options": paymentEnumerators.status
      },
    ),
  },
];