import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.identificationDocument.fields.id'),
  },
  {
    name: 'idNumber',
    label: i18n('entities.identificationDocument.fields.idNumber'),
  },
  {
    name: 'idImages',
    label: i18n('entities.identificationDocument.fields.idImages'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'idDetails',
    label: i18n('entities.identificationDocument.fields.idDetails'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.identificationDocument.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.identificationDocument.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
