import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'shopName',
    label: i18n('entities.shop.fields.shopName'),
    schema: schemas.string(
      i18n('entities.shop.fields.shopName'),
      {},
    ),
  },
  {
    name: 'shopProducts',
    label: i18n('entities.shop.fields.shopProducts'),
    schema: schemas.relationToMany(
      i18n('entities.shop.fields.shopProducts'),
      {},
    ),
  },
  {
    name: 'shopOfShopOwner',
    label: i18n('entities.shop.fields.shopOfShopOwner'),
    schema: schemas.relationToOne(
      i18n('entities.shop.fields.shopOfShopOwner'),
      {},
    ),
  },
];