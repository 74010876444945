const reviewsOfGaragesEnumerators = {
  garageReviewRating: [
    '1',
    '2',
    '3',
    '4',
    '5',
  ],
};

export default reviewsOfGaragesEnumerators;
