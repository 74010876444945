import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.advertPackage.fields.id'),
  },
  {
    name: 'advertPackageName',
    label: i18n('entities.advertPackage.fields.advertPackageName'),
  },
  {
    name: 'advertPackageDescription',
    label: i18n('entities.advertPackage.fields.advertPackageDescription'),
  },
  {
    name: 'advertPackageNoOfProducts',
    label: i18n('entities.advertPackage.fields.advertPackageNoOfProducts'),
  },
  {
    name: 'advertPackageCostOfPackage',
    label: i18n('entities.advertPackage.fields.advertPackageCostOfPackage'),
  },
  {
    name: 'advertPackageDuration',
    label: i18n('entities.advertPackage.fields.advertPackageDuration'),
  },
  {
    name: 'advertPackagePriorityLevel',
    label: i18n('entities.advertPackage.fields.advertPackagePriorityLevel'),
  },
  {
    name: 'advertPackageRadiusDistance',
    label: i18n('entities.advertPackage.fields.advertPackageRadiusDistance'),
  },
  {
    name: 'advertPackageAdvertisement',
    label: i18n('entities.advertPackage.fields.advertPackageAdvertisement'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'advertPackageForShopOwner',
    label: i18n('entities.advertPackage.fields.advertPackageForShopOwner'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.advertPackage.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.advertPackage.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
