import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'ofUser',
    label: i18n('entities.certificateOfRegistration.fields.ofUser'),
    schema: schemas.relationToOne(
      i18n('entities.certificateOfRegistration.fields.ofUser'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'ofGarage',
    label: i18n('entities.certificateOfRegistration.fields.ofGarage'),
    schema: schemas.relationToMany(
      i18n('entities.certificateOfRegistration.fields.ofGarage'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'garageCertificateImages',
    label: i18n('entities.certificateOfRegistration.fields.garageCertificateImages'),
    schema: schemas.files(
      i18n('entities.certificateOfRegistration.fields.garageCertificateImages'),
      {
        "min": 1,
        "required": true,
        "max": 3
      },
    ),
  },
];