import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.garage.fields.id'),
  },
  {
    name: 'garageName',
    label: i18n('entities.garage.fields.garageName'),
  },
  {
    name: 'garageOwnedByMechanic',
    label: i18n('entities.garage.fields.garageOwnedByMechanic'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'garageDescription',
    label: i18n('entities.garage.fields.garageDescription'),
  },
  {
    name: 'garageLocation',
    label: i18n('entities.garage.fields.garageLocation'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'garageImages',
    label: i18n('entities.garage.fields.garageImages'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'garageCertificateOfRegistration',
    label: i18n('entities.garage.fields.garageCertificateOfRegistration'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'garageReview',
    label: i18n('entities.garage.fields.garageReview'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.garage.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.garage.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
