import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import preferredModeOfContactEnumerators from 'src/modules/preferredModeOfContact/preferredModeOfContactEnumerators';

export default [
  {
    name: 'name',
    label: i18n('entities.preferredModeOfContact.fields.name'),
    schema: schemas.string(
      i18n('entities.preferredModeOfContact.fields.name'),
      {
        "required": true,
        "min": 2
      },
    ),
  },
  {
    name: 'description',
    label: i18n('entities.preferredModeOfContact.fields.description'),
    schema: schemas.string(
      i18n('entities.preferredModeOfContact.fields.description'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.preferredModeOfContact.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.preferredModeOfContact.fields.status'),
      {
        "options": preferredModeOfContactEnumerators.status
      },
    ),
  },
];