import list from 'src/modules/responseDocument/list/responseDocumentListReducers';
import form from 'src/modules/responseDocument/form/responseDocumentFormReducers';
import view from 'src/modules/responseDocument/view/responseDocumentViewReducers';
import destroy from 'src/modules/responseDocument/destroy/responseDocumentDestroyReducers';
import importerReducer from 'src/modules/responseDocument/importer/responseDocumentImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
