import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'locationName',
    label: i18n('entities.location.fields.locationName'),
    schema: schemas.string(
      i18n('entities.location.fields.locationName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'locationDescription',
    label: i18n('entities.location.fields.locationDescription'),
    schema: schemas.string(
      i18n('entities.location.fields.locationDescription'),
      {},
    ),
  },
  {
    name: 'coordinates',
    label: i18n('entities.location.fields.coordinates'),
    schema: schemas.string(
      i18n('entities.location.fields.coordinates'),
      {},
    ),
  },
];