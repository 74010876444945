import list from 'src/modules/adPackageOrder/list/adPackageOrderListReducers';
import form from 'src/modules/adPackageOrder/form/adPackageOrderFormReducers';
import view from 'src/modules/adPackageOrder/view/adPackageOrderViewReducers';
import destroy from 'src/modules/adPackageOrder/destroy/adPackageOrderDestroyReducers';
import importerReducer from 'src/modules/adPackageOrder/importer/adPackageOrderImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
