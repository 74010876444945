import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import preferredModeOfContact from 'src/modules/preferredModeOfContact/preferredModeOfContactReducers';
import car from 'src/modules/car/carReducers';
import location from 'src/modules/location/locationReducers';
import carModel from 'src/modules/carModel/carModelReducers';
import carMakes from 'src/modules/carMakes/carMakesReducers';
import clientRequestToMechanic from 'src/modules/clientRequestToMechanic/clientRequestToMechanicReducers';
import mechanic from 'src/modules/mechanic/mechanicReducers';
import paymentMethod from 'src/modules/paymentMethod/paymentMethodReducers';
import payment from 'src/modules/payment/paymentReducers';
import responseDocument from 'src/modules/responseDocument/responseDocumentReducers';
import mechanicResponseToClientRequest from 'src/modules/mechanicResponseToClientRequest/mechanicResponseToClientRequestReducers';
import clientsResponseToMechanicResponse from 'src/modules/clientsResponseToMechanicResponse/clientsResponseToMechanicResponseReducers';
import garage from 'src/modules/garage/garageReducers';
import reviewsOfGarages from 'src/modules/reviewsOfGarages/reviewsOfGaragesReducers';
import servicesOffered from 'src/modules/servicesOffered/servicesOfferedReducers';
import reviewOfMechanic from 'src/modules/reviewOfMechanic/reviewOfMechanicReducers';
import identificationDocument from 'src/modules/identificationDocument/identificationDocumentReducers';
import certificateOfRegistration from 'src/modules/certificateOfRegistration/certificateOfRegistrationReducers';
import clientOrder from 'src/modules/clientOrder/clientOrderReducers';
import convenienceFee from 'src/modules/convenienceFee/convenienceFeeReducers';
import shopOwner from 'src/modules/shopOwner/shopOwnerReducers';
import shopProduct from 'src/modules/shopProduct/shopProductReducers';
import shop from 'src/modules/shop/shopReducers';
import shopProductCategory from 'src/modules/shopProductCategory/shopProductCategoryReducers';
import advertisements from 'src/modules/advertisements/advertisementsReducers';
import advertPayment from 'src/modules/advertPayment/advertPaymentReducers';
import advertPackage from 'src/modules/advertPackage/advertPackageReducers';
import adPackageOrder from 'src/modules/adPackageOrder/adPackageOrderReducers';
import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    preferredModeOfContact,
    car,
    location,
    carModel,
    carMakes,
    clientRequestToMechanic,
    mechanic,
    paymentMethod,
    payment,
    responseDocument,
    mechanicResponseToClientRequest,
    clientsResponseToMechanicResponse,
    garage,
    reviewsOfGarages,
    servicesOffered,
    reviewOfMechanic,
    identificationDocument,
    certificateOfRegistration,
    clientOrder,
    convenienceFee,
    shopOwner,
    shopProduct,
    shop,
    shopProductCategory,
    advertisements,
    advertPayment,
    advertPackage,
    adPackageOrder,
  });
