import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'carMakeName',
    label: i18n('entities.carMakes.fields.carMakeName'),
    schema: schemas.string(
      i18n('entities.carMakes.fields.carMakeName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'carMakeDescription',
    label: i18n('entities.carMakes.fields.carMakeDescription'),
    schema: schemas.string(
      i18n('entities.carMakes.fields.carMakeDescription'),
      {},
    ),
  },
];