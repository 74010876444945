import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/responseDocument/importer/responseDocumentImporterSelectors';
import ResponseDocumentService from 'src/modules/responseDocument/responseDocumentService';
import fields from 'src/modules/responseDocument/importer/responseDocumentImporterFields';
import { i18n } from 'src/i18n';

const responseDocumentImporterActions = importerActions(
  'RESPONSEDOCUMENT_IMPORTER',
  selectors,
  ResponseDocumentService.import,
  fields,
  i18n('entities.responseDocument.importer.fileName'),
);

export default responseDocumentImporterActions;