import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'carModelName',
    label: i18n('entities.carModel.fields.carModelName'),
    schema: schemas.string(
      i18n('entities.carModel.fields.carModelName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'carModelDescription',
    label: i18n('entities.carModel.fields.carModelDescription'),
    schema: schemas.string(
      i18n('entities.carModel.fields.carModelDescription'),
      {},
    ),
  },
];