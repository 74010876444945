import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.adPackageOrder.fields.id'),
  },
  {
    name: 'adOrderBillingFirstName',
    label: i18n('entities.adPackageOrder.fields.adOrderBillingFirstName'),
  },
  {
    name: 'adOrderBillingLastName',
    label: i18n('entities.adPackageOrder.fields.adOrderBillingLastName'),
  },
  {
    name: 'adOrderForPackageId',
    label: i18n('entities.adPackageOrder.fields.adOrderForPackageId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'adOrderOfLoggedInUserId',
    label: i18n('entities.adPackageOrder.fields.adOrderOfLoggedInUserId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'adOrderBillingPhoneNumber',
    label: i18n('entities.adPackageOrder.fields.adOrderBillingPhoneNumber'),
  },
  {
    name: 'adOrderBillingEmail',
    label: i18n('entities.adPackageOrder.fields.adOrderBillingEmail'),
  },
  {
    name: 'adOrderCompanyName',
    label: i18n('entities.adPackageOrder.fields.adOrderCompanyName'),
  },
  {
    name: 'adOrderPaymentId',
    label: i18n('entities.adPackageOrder.fields.adOrderPaymentId'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'adOrderStatus',
    label: i18n('entities.adPackageOrder.fields.adOrderStatus'),
  },
  {
    name: 'adOrderTotal',
    label: i18n('entities.adPackageOrder.fields.adOrderTotal'),
  },
  {
    name: 'adOrderBillingAdditionalInfo',
    label: i18n('entities.adPackageOrder.fields.adOrderBillingAdditionalInfo'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.adPackageOrder.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.adPackageOrder.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
