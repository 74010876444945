import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'convenienceFeeName',
    label: i18n('entities.convenienceFee.fields.convenienceFeeName'),
    schema: schemas.string(
      i18n('entities.convenienceFee.fields.convenienceFeeName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'convenienceFeeDescription',
    label: i18n('entities.convenienceFee.fields.convenienceFeeDescription'),
    schema: schemas.string(
      i18n('entities.convenienceFee.fields.convenienceFeeDescription'),
      {},
    ),
  },
  {
    name: 'convenienceFeeServices',
    label: i18n('entities.convenienceFee.fields.convenienceFeeServices'),
    schema: schemas.relationToMany(
      i18n('entities.convenienceFee.fields.convenienceFeeServices'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'convenienceFeeAmount',
    label: i18n('entities.convenienceFee.fields.convenienceFeeAmount'),
    schema: schemas.integer(
      i18n('entities.convenienceFee.fields.convenienceFeeAmount'),
      {
        "required": true
      },
    ),
  },
];