import list from 'src/modules/servicesOffered/list/servicesOfferedListReducers';
import form from 'src/modules/servicesOffered/form/servicesOfferedFormReducers';
import view from 'src/modules/servicesOffered/view/servicesOfferedViewReducers';
import destroy from 'src/modules/servicesOffered/destroy/servicesOfferedDestroyReducers';
import importerReducer from 'src/modules/servicesOffered/importer/servicesOfferedImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
