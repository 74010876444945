import list from 'src/modules/garage/list/garageListReducers';
import form from 'src/modules/garage/form/garageFormReducers';
import view from 'src/modules/garage/view/garageViewReducers';
import destroy from 'src/modules/garage/destroy/garageDestroyReducers';
import importerReducer from 'src/modules/garage/importer/garageImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
