import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/shopProductCategory/importer/shopProductCategoryImporterSelectors';
import ShopProductCategoryService from 'src/modules/shopProductCategory/shopProductCategoryService';
import fields from 'src/modules/shopProductCategory/importer/shopProductCategoryImporterFields';
import { i18n } from 'src/i18n';

const shopProductCategoryImporterActions = importerActions(
  'SHOPPRODUCTCATEGORY_IMPORTER',
  selectors,
  ShopProductCategoryService.import,
  fields,
  i18n('entities.shopProductCategory.importer.fileName'),
);

export default shopProductCategoryImporterActions;