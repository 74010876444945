import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import clientRequestToMechanicEnumerators from 'src/modules/clientRequestToMechanic/clientRequestToMechanicEnumerators';
import moment from 'moment';

export default [
  {
    name: 'requestForCar',
    label: i18n('entities.clientRequestToMechanic.fields.requestForCar'),
    schema: schemas.relationToOne(
      i18n('entities.clientRequestToMechanic.fields.requestForCar'),
      {},
    ),
  },
  {
    name: 'requestDetail',
    label: i18n('entities.clientRequestToMechanic.fields.requestDetail'),
    schema: schemas.string(
      i18n('entities.clientRequestToMechanic.fields.requestDetail'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'affects',
    label: i18n('entities.clientRequestToMechanic.fields.affects'),
    schema: schemas.relationToOne(
      i18n('entities.clientRequestToMechanic.fields.affects'),
      {},
    ),
  },
  {
    name: 'status',
    label: i18n('entities.clientRequestToMechanic.fields.status'),
    schema: schemas.enumerator(
      i18n('entities.clientRequestToMechanic.fields.status'),
      {
        "options": clientRequestToMechanicEnumerators.status
      },
    ),
  },
  {
    name: 'mechanicId',
    label: i18n('entities.clientRequestToMechanic.fields.mechanicId'),
    schema: schemas.relationToOne(
      i18n('entities.clientRequestToMechanic.fields.mechanicId'),
      {},
    ),
  },
  {
    name: 'mechanicResponse',
    label: i18n('entities.clientRequestToMechanic.fields.mechanicResponse'),
    schema: schemas.relationToMany(
      i18n('entities.clientRequestToMechanic.fields.mechanicResponse'),
      {},
    ),
  },
  {
    name: 'dateFulfilled',
    label: i18n('entities.clientRequestToMechanic.fields.dateFulfilled'),
    schema: schemas.date(
      i18n('entities.clientRequestToMechanic.fields.dateFulfilled'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
];