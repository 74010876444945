import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'carRegNumber',
    label: i18n('entities.car.fields.carRegNumber'),
    schema: schemas.string(
      i18n('entities.car.fields.carRegNumber'),
      {
        "required": true,
        "min": 3
      },
    ),
  },
  {
    name: 'carOwnedByUser',
    label: i18n('entities.car.fields.carOwnedByUser'),
    schema: schemas.relationToOne(
      i18n('entities.car.fields.carOwnedByUser'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'carLocatedAt',
    label: i18n('entities.car.fields.carLocatedAt'),
    schema: schemas.relationToOne(
      i18n('entities.car.fields.carLocatedAt'),
      {},
    ),
  },
  {
    name: 'carMake',
    label: i18n('entities.car.fields.carMake'),
    schema: schemas.relationToOne(
      i18n('entities.car.fields.carMake'),
      {},
    ),
  },
  {
    name: 'carModel',
    label: i18n('entities.car.fields.carModel'),
    schema: schemas.relationToOne(
      i18n('entities.car.fields.carModel'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'carMake',
    label: i18n('entities.car.fields.carMake'),
    schema: schemas.relationToOne(
      i18n('entities.car.fields.carMake'),
      {},
    ),
  },
  {
    name: 'carsAdditionalInfo',
    label: i18n('entities.car.fields.carsAdditionalInfo'),
    schema: schemas.string(
      i18n('entities.car.fields.carsAdditionalInfo'),
      {},
    ),
  },
];