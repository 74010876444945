import list from 'src/modules/shopProductCategory/list/shopProductCategoryListReducers';
import form from 'src/modules/shopProductCategory/form/shopProductCategoryFormReducers';
import view from 'src/modules/shopProductCategory/view/shopProductCategoryViewReducers';
import destroy from 'src/modules/shopProductCategory/destroy/shopProductCategoryDestroyReducers';
import importerReducer from 'src/modules/shopProductCategory/importer/shopProductCategoryImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
