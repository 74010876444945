import Permissions from 'src/security/permissions';
// import config from 'src/config';
import Roles from 'src/security/roles';

const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/dashboard',
    loader: () =>
      import('src/view/dashboard/DashboardPage'),
    permissionRequired: Roles.values.admin,
    // permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  // config.isPlanEnabled && {
  //   path: '/plan',
  //   loader: () => import('src/view/plan/PlanPage'),
  //   permissionRequired: permissions.planRead,
  //   exact: true,
  // },

  {
    path: '/user',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/preferred-mode-of-contact',
    loader: () =>
      import('src/view/preferredModeOfContact/list/PreferredModeOfContactListPage'),
    permissionRequired: permissions.preferredModeOfContactRead,
    exact: true,
  },
  {
    path: '/preferred-mode-of-contact/new',
    loader: () =>
      import('src/view/preferredModeOfContact/form/PreferredModeOfContactFormPage'),
    permissionRequired: permissions.preferredModeOfContactCreate,
    exact: true,
  },
  {
    path: '/preferred-mode-of-contact/importer',
    loader: () =>
      import(
        'src/view/preferredModeOfContact/importer/PreferredModeOfContactImporterPage'
      ),
    permissionRequired: permissions.preferredModeOfContactImport,
    exact: true,
  },
  {
    path: '/preferred-mode-of-contact/:id/edit',
    loader: () =>
      import('src/view/preferredModeOfContact/form/PreferredModeOfContactFormPage'),
    permissionRequired: permissions.preferredModeOfContactEdit,
    exact: true,
  },
  {
    path: '/preferred-mode-of-contact/:id',
    loader: () =>
      import('src/view/preferredModeOfContact/view/PreferredModeOfContactViewPage'),
    permissionRequired: permissions.preferredModeOfContactRead,
    exact: true,
  },

  {
    path: '/car',
    loader: () =>
      import('src/view/car/list/CarListPage'),
    permissionRequired: permissions.carRead,
    exact: true,
  },
  {
    path: '/car/new',
    loader: () =>
      import('src/view/car/form/CarFormPage'),
    permissionRequired: permissions.carCreate,
    exact: true,
  },
  {
    path: '/car/importer',
    loader: () =>
      import(
        'src/view/car/importer/CarImporterPage'
      ),
    permissionRequired: permissions.carImport,
    exact: true,
  },
  {
    path: '/car/:id/edit',
    loader: () =>
      import('src/view/car/form/CarFormPage'),
    permissionRequired: permissions.carEdit,
    exact: true,
  },
  {
    path: '/car/:id',
    loader: () =>
      import('src/view/car/view/CarViewPage'),
    permissionRequired: permissions.carRead,
    exact: true,
  },

  {
    path: '/location',
    loader: () =>
      import('src/view/location/list/LocationListPage'),
    permissionRequired: permissions.locationRead,
    exact: true,
  },
  {
    path: '/location/new',
    loader: () =>
      import('src/view/location/form/LocationFormPage'),
    permissionRequired: permissions.locationCreate,
    exact: true,
  },
  {
    path: '/location/importer',
    loader: () =>
      import(
        'src/view/location/importer/LocationImporterPage'
      ),
    permissionRequired: permissions.locationImport,
    exact: true,
  },
  {
    path: '/location/:id/edit',
    loader: () =>
      import('src/view/location/form/LocationFormPage'),
    permissionRequired: permissions.locationEdit,
    exact: true,
  },
  {
    path: '/location/:id',
    loader: () =>
      import('src/view/location/view/LocationViewPage'),
    permissionRequired: permissions.locationRead,
    exact: true,
  },

  {
    path: '/car-model',
    loader: () =>
      import('src/view/carModel/list/CarModelListPage'),
    permissionRequired: permissions.carModelRead,
    exact: true,
  },
  {
    path: '/car-model/new',
    loader: () =>
      import('src/view/carModel/form/CarModelFormPage'),
    permissionRequired: permissions.carModelCreate,
    exact: true,
  },
  {
    path: '/car-model/importer',
    loader: () =>
      import(
        'src/view/carModel/importer/CarModelImporterPage'
      ),
    permissionRequired: permissions.carModelImport,
    exact: true,
  },
  {
    path: '/car-model/:id/edit',
    loader: () =>
      import('src/view/carModel/form/CarModelFormPage'),
    permissionRequired: permissions.carModelEdit,
    exact: true,
  },
  {
    path: '/car-model/:id',
    loader: () =>
      import('src/view/carModel/view/CarModelViewPage'),
    permissionRequired: permissions.carModelRead,
    exact: true,
  },

  {
    path: '/car-makes',
    loader: () =>
      import('src/view/carMakes/list/CarMakesListPage'),
    permissionRequired: permissions.carMakesRead,
    exact: true,
  },
  {
    path: '/car-makes/new',
    loader: () =>
      import('src/view/carMakes/form/CarMakesFormPage'),
    permissionRequired: permissions.carMakesCreate,
    exact: true,
  },
  {
    path: '/car-makes/importer',
    loader: () =>
      import(
        'src/view/carMakes/importer/CarMakesImporterPage'
      ),
    permissionRequired: permissions.carMakesImport,
    exact: true,
  },
  {
    path: '/car-makes/:id/edit',
    loader: () =>
      import('src/view/carMakes/form/CarMakesFormPage'),
    permissionRequired: permissions.carMakesEdit,
    exact: true,
  },
  {
    path: '/car-makes/:id',
    loader: () =>
      import('src/view/carMakes/view/CarMakesViewPage'),
    permissionRequired: permissions.carMakesRead,
    exact: true,
  },

  {
    path: '/client-request-to-mechanic',
    loader: () =>
      import('src/view/clientRequestToMechanic/list/ClientRequestToMechanicListPage'),
    permissionRequired: permissions.clientRequestToMechanicRead,
    exact: true,
  },
  {
    path: '/client-request-to-mechanic/new',
    loader: () =>
      import('src/view/clientRequestToMechanic/form/ClientRequestToMechanicFormPage'),
    permissionRequired: permissions.clientRequestToMechanicCreate,
    exact: true,
  },
  {
    path: '/client-request-to-mechanic/importer',
    loader: () =>
      import(
        'src/view/clientRequestToMechanic/importer/ClientRequestToMechanicImporterPage'
      ),
    permissionRequired: permissions.clientRequestToMechanicImport,
    exact: true,
  },
  {
    path: '/client-request-to-mechanic/:id/edit',
    loader: () =>
      import('src/view/clientRequestToMechanic/form/ClientRequestToMechanicFormPage'),
    permissionRequired: permissions.clientRequestToMechanicEdit,
    exact: true,
  },
  {
    path: '/client-request-to-mechanic/:id',
    loader: () =>
      import('src/view/clientRequestToMechanic/view/ClientRequestToMechanicViewPage'),
    permissionRequired: permissions.clientRequestToMechanicRead,
    exact: true,
  },

  {
    path: '/mechanic',
    loader: () =>
      import('src/view/mechanic/list/MechanicListPage'),
    permissionRequired: permissions.mechanicRead,
    exact: true,
  },
  {
    path: '/mechanic/new',
    loader: () =>
      import('src/view/mechanic/form/MechanicFormPage'),
    permissionRequired: permissions.mechanicCreate,
    exact: true,
  },
  {
    path: '/mechanic/importer',
    loader: () =>
      import(
        'src/view/mechanic/importer/MechanicImporterPage'
      ),
    permissionRequired: permissions.mechanicImport,
    exact: true,
  },
  {
    path: '/mechanic/:id/edit',
    loader: () =>
      import('src/view/mechanic/form/MechanicFormPage'),
    permissionRequired: permissions.mechanicEdit,
    exact: true,
  },
  {
    path: '/mechanic/:id',
    loader: () =>
      import('src/view/mechanic/view/MechanicViewPage'),
    permissionRequired: permissions.mechanicRead,
    exact: true,
  },

  {
    path: '/payment-method',
    loader: () =>
      import('src/view/paymentMethod/list/PaymentMethodListPage'),
    permissionRequired: permissions.paymentMethodRead,
    exact: true,
  },
  {
    path: '/payment-method/new',
    loader: () =>
      import('src/view/paymentMethod/form/PaymentMethodFormPage'),
    permissionRequired: permissions.paymentMethodCreate,
    exact: true,
  },
  {
    path: '/payment-method/importer',
    loader: () =>
      import(
        'src/view/paymentMethod/importer/PaymentMethodImporterPage'
      ),
    permissionRequired: permissions.paymentMethodImport,
    exact: true,
  },
  {
    path: '/payment-method/:id/edit',
    loader: () =>
      import('src/view/paymentMethod/form/PaymentMethodFormPage'),
    permissionRequired: permissions.paymentMethodEdit,
    exact: true,
  },
  {
    path: '/payment-method/:id',
    loader: () =>
      import('src/view/paymentMethod/view/PaymentMethodViewPage'),
    permissionRequired: permissions.paymentMethodRead,
    exact: true,
  },

  {
    path: '/payment',
    loader: () =>
      import('src/view/payment/list/PaymentListPage'),
    permissionRequired: permissions.paymentRead,
    exact: true,
  },
  {
    path: '/payment/new',
    loader: () =>
      import('src/view/payment/form/PaymentFormPage'),
    permissionRequired: permissions.paymentCreate,
    exact: true,
  },
  {
    path: '/payment/importer',
    loader: () =>
      import(
        'src/view/payment/importer/PaymentImporterPage'
      ),
    permissionRequired: permissions.paymentImport,
    exact: true,
  },
  {
    path: '/payment/:id/edit',
    loader: () =>
      import('src/view/payment/form/PaymentFormPage'),
    permissionRequired: permissions.paymentEdit,
    exact: true,
  },
  {
    path: '/payment/:id',
    loader: () =>
      import('src/view/payment/view/PaymentViewPage'),
    permissionRequired: permissions.paymentRead,
    exact: true,
  },

  {
    path: '/response-document',
    loader: () =>
      import('src/view/responseDocument/list/ResponseDocumentListPage'),
    permissionRequired: permissions.responseDocumentRead,
    exact: true,
  },
  {
    path: '/response-document/new',
    loader: () =>
      import('src/view/responseDocument/form/ResponseDocumentFormPage'),
    permissionRequired: permissions.responseDocumentCreate,
    exact: true,
  },
  {
    path: '/response-document/importer',
    loader: () =>
      import(
        'src/view/responseDocument/importer/ResponseDocumentImporterPage'
      ),
    permissionRequired: permissions.responseDocumentImport,
    exact: true,
  },
  {
    path: '/response-document/:id/edit',
    loader: () =>
      import('src/view/responseDocument/form/ResponseDocumentFormPage'),
    permissionRequired: permissions.responseDocumentEdit,
    exact: true,
  },
  {
    path: '/response-document/:id',
    loader: () =>
      import('src/view/responseDocument/view/ResponseDocumentViewPage'),
    permissionRequired: permissions.responseDocumentRead,
    exact: true,
  },

  {
    path: '/mechanic-response-to-client-request',
    loader: () =>
      import('src/view/mechanicResponseToClientRequest/list/MechanicResponseToClientRequestListPage'),
    permissionRequired: permissions.mechanicResponseToClientRequestRead,
    exact: true,
  },
  {
    path: '/mechanic-response-to-client-request/new',
    loader: () =>
      import('src/view/mechanicResponseToClientRequest/form/MechanicResponseToClientRequestFormPage'),
    permissionRequired: permissions.mechanicResponseToClientRequestCreate,
    exact: true,
  },
  {
    path: '/mechanic-response-to-client-request/importer',
    loader: () =>
      import(
        'src/view/mechanicResponseToClientRequest/importer/MechanicResponseToClientRequestImporterPage'
      ),
    permissionRequired: permissions.mechanicResponseToClientRequestImport,
    exact: true,
  },
  {
    path: '/mechanic-response-to-client-request/:id/edit',
    loader: () =>
      import('src/view/mechanicResponseToClientRequest/form/MechanicResponseToClientRequestFormPage'),
    permissionRequired: permissions.mechanicResponseToClientRequestEdit,
    exact: true,
  },
  {
    path: '/mechanic-response-to-client-request/:id',
    loader: () =>
      import('src/view/mechanicResponseToClientRequest/view/MechanicResponseToClientRequestViewPage'),
    permissionRequired: permissions.mechanicResponseToClientRequestRead,
    exact: true,
  },

  {
    path: '/clients-response-to-mechanic-response',
    loader: () =>
      import('src/view/clientsResponseToMechanicResponse/list/ClientsResponseToMechanicResponseListPage'),
    permissionRequired: permissions.clientsResponseToMechanicResponseRead,
    exact: true,
  },
  {
    path: '/clients-response-to-mechanic-response/new',
    loader: () =>
      import('src/view/clientsResponseToMechanicResponse/form/ClientsResponseToMechanicResponseFormPage'),
    permissionRequired: permissions.clientsResponseToMechanicResponseCreate,
    exact: true,
  },
  {
    path: '/clients-response-to-mechanic-response/importer',
    loader: () =>
      import(
        'src/view/clientsResponseToMechanicResponse/importer/ClientsResponseToMechanicResponseImporterPage'
      ),
    permissionRequired: permissions.clientsResponseToMechanicResponseImport,
    exact: true,
  },
  {
    path: '/clients-response-to-mechanic-response/:id/edit',
    loader: () =>
      import('src/view/clientsResponseToMechanicResponse/form/ClientsResponseToMechanicResponseFormPage'),
    permissionRequired: permissions.clientsResponseToMechanicResponseEdit,
    exact: true,
  },
  {
    path: '/clients-response-to-mechanic-response/:id',
    loader: () =>
      import('src/view/clientsResponseToMechanicResponse/view/ClientsResponseToMechanicResponseViewPage'),
    permissionRequired: permissions.clientsResponseToMechanicResponseRead,
    exact: true,
  },

  {
    path: '/garage',
    loader: () =>
      import('src/view/garage/list/GarageListPage'),
    permissionRequired: permissions.garageRead,
    exact: true,
  },
  {
    path: '/garage/new',
    loader: () =>
      import('src/view/garage/form/GarageFormPage'),
    permissionRequired: permissions.garageCreate,
    exact: true,
  },
  {
    path: '/garage/importer',
    loader: () =>
      import(
        'src/view/garage/importer/GarageImporterPage'
      ),
    permissionRequired: permissions.garageImport,
    exact: true,
  },
  {
    path: '/garage/:id/edit',
    loader: () =>
      import('src/view/garage/form/GarageFormPage'),
    permissionRequired: permissions.garageEdit,
    exact: true,
  },
  {
    path: '/garage/:id',
    loader: () =>
      import('src/view/garage/view/GarageViewPage'),
    permissionRequired: permissions.garageRead,
    exact: true,
  },

  {
    path: '/reviews-of-garages',
    loader: () =>
      import('src/view/reviewsOfGarages/list/ReviewsOfGaragesListPage'),
    permissionRequired: permissions.reviewsOfGaragesRead,
    exact: true,
  },
  {
    path: '/reviews-of-garages/new',
    loader: () =>
      import('src/view/reviewsOfGarages/form/ReviewsOfGaragesFormPage'),
    permissionRequired: permissions.reviewsOfGaragesCreate,
    exact: true,
  },
  {
    path: '/reviews-of-garages/importer',
    loader: () =>
      import(
        'src/view/reviewsOfGarages/importer/ReviewsOfGaragesImporterPage'
      ),
    permissionRequired: permissions.reviewsOfGaragesImport,
    exact: true,
  },
  {
    path: '/reviews-of-garages/:id/edit',
    loader: () =>
      import('src/view/reviewsOfGarages/form/ReviewsOfGaragesFormPage'),
    permissionRequired: permissions.reviewsOfGaragesEdit,
    exact: true,
  },
  {
    path: '/reviews-of-garages/:id',
    loader: () =>
      import('src/view/reviewsOfGarages/view/ReviewsOfGaragesViewPage'),
    permissionRequired: permissions.reviewsOfGaragesRead,
    exact: true,
  },

  {
    path: '/services-offered',
    loader: () =>
      import('src/view/servicesOffered/list/ServicesOfferedListPage'),
    permissionRequired: permissions.servicesOfferedRead,
    exact: true,
  },
  {
    path: '/services-offered/new',
    loader: () =>
      import('src/view/servicesOffered/form/ServicesOfferedFormPage'),
    permissionRequired: permissions.servicesOfferedCreate,
    exact: true,
  },
  {
    path: '/services-offered/importer',
    loader: () =>
      import(
        'src/view/servicesOffered/importer/ServicesOfferedImporterPage'
      ),
    permissionRequired: permissions.servicesOfferedImport,
    exact: true,
  },
  {
    path: '/services-offered/:id/edit',
    loader: () =>
      import('src/view/servicesOffered/form/ServicesOfferedFormPage'),
    permissionRequired: permissions.servicesOfferedEdit,
    exact: true,
  },
  {
    path: '/services-offered/:id',
    loader: () =>
      import('src/view/servicesOffered/view/ServicesOfferedViewPage'),
    permissionRequired: permissions.servicesOfferedRead,
    exact: true,
  },

  {
    path: '/review-of-mechanic',
    loader: () =>
      import('src/view/reviewOfMechanic/list/ReviewOfMechanicListPage'),
    permissionRequired: permissions.reviewOfMechanicRead,
    exact: true,
  },
  {
    path: '/review-of-mechanic/new',
    loader: () =>
      import('src/view/reviewOfMechanic/form/ReviewOfMechanicFormPage'),
    permissionRequired: permissions.reviewOfMechanicCreate,
    exact: true,
  },
  {
    path: '/review-of-mechanic/importer',
    loader: () =>
      import(
        'src/view/reviewOfMechanic/importer/ReviewOfMechanicImporterPage'
      ),
    permissionRequired: permissions.reviewOfMechanicImport,
    exact: true,
  },
  {
    path: '/review-of-mechanic/:id/edit',
    loader: () =>
      import('src/view/reviewOfMechanic/form/ReviewOfMechanicFormPage'),
    permissionRequired: permissions.reviewOfMechanicEdit,
    exact: true,
  },
  {
    path: '/review-of-mechanic/:id',
    loader: () =>
      import('src/view/reviewOfMechanic/view/ReviewOfMechanicViewPage'),
    permissionRequired: permissions.reviewOfMechanicRead,
    exact: true,
  },

  {
    path: '/identification-document',
    loader: () =>
      import('src/view/identificationDocument/list/IdentificationDocumentListPage'),
    permissionRequired: permissions.identificationDocumentRead,
    exact: true,
  },
  {
    path: '/identification-document/new',
    loader: () =>
      import('src/view/identificationDocument/form/IdentificationDocumentFormPage'),
    permissionRequired: permissions.identificationDocumentCreate,
    exact: true,
  },
  {
    path: '/identification-document/importer',
    loader: () =>
      import(
        'src/view/identificationDocument/importer/IdentificationDocumentImporterPage'
      ),
    permissionRequired: permissions.identificationDocumentImport,
    exact: true,
  },
  {
    path: '/identification-document/:id/edit',
    loader: () =>
      import('src/view/identificationDocument/form/IdentificationDocumentFormPage'),
    permissionRequired: permissions.identificationDocumentEdit,
    exact: true,
  },
  {
    path: '/identification-document/:id',
    loader: () =>
      import('src/view/identificationDocument/view/IdentificationDocumentViewPage'),
    permissionRequired: permissions.identificationDocumentRead,
    exact: true,
  },

  {
    path: '/certificate-of-registration',
    loader: () =>
      import('src/view/certificateOfRegistration/list/CertificateOfRegistrationListPage'),
    permissionRequired: permissions.certificateOfRegistrationRead,
    exact: true,
  },
  {
    path: '/certificate-of-registration/new',
    loader: () =>
      import('src/view/certificateOfRegistration/form/CertificateOfRegistrationFormPage'),
    permissionRequired: permissions.certificateOfRegistrationCreate,
    exact: true,
  },
  {
    path: '/certificate-of-registration/importer',
    loader: () =>
      import(
        'src/view/certificateOfRegistration/importer/CertificateOfRegistrationImporterPage'
      ),
    permissionRequired: permissions.certificateOfRegistrationImport,
    exact: true,
  },
  {
    path: '/certificate-of-registration/:id/edit',
    loader: () =>
      import('src/view/certificateOfRegistration/form/CertificateOfRegistrationFormPage'),
    permissionRequired: permissions.certificateOfRegistrationEdit,
    exact: true,
  },
  {
    path: '/certificate-of-registration/:id',
    loader: () =>
      import('src/view/certificateOfRegistration/view/CertificateOfRegistrationViewPage'),
    permissionRequired: permissions.certificateOfRegistrationRead,
    exact: true,
  },

  {
    path: '/client-order',
    loader: () =>
      import('src/view/clientOrder/list/ClientOrderListPage'),
    permissionRequired: permissions.clientOrderRead,
    exact: true,
  },
  {
    path: '/client-order/new',
    loader: () =>
      import('src/view/clientOrder/form/ClientOrderFormPage'),
    permissionRequired: permissions.clientOrderCreate,
    exact: true,
  },
  {
    path: '/client-order/importer',
    loader: () =>
      import(
        'src/view/clientOrder/importer/ClientOrderImporterPage'
      ),
    permissionRequired: permissions.clientOrderImport,
    exact: true,
  },
  {
    path: '/client-order/:id/edit',
    loader: () =>
      import('src/view/clientOrder/form/ClientOrderFormPage'),
    permissionRequired: permissions.clientOrderEdit,
    exact: true,
  },
  {
    path: '/client-order/:id',
    loader: () =>
      import('src/view/clientOrder/view/ClientOrderViewPage'),
    permissionRequired: permissions.clientOrderRead,
    exact: true,
  },

  {
    path: '/convenience-fee',
    loader: () =>
      import('src/view/convenienceFee/list/ConvenienceFeeListPage'),
    permissionRequired: permissions.convenienceFeeRead,
    exact: true,
  },
  {
    path: '/convenience-fee/new',
    loader: () =>
      import('src/view/convenienceFee/form/ConvenienceFeeFormPage'),
    permissionRequired: permissions.convenienceFeeCreate,
    exact: true,
  },
  {
    path: '/convenience-fee/importer',
    loader: () =>
      import(
        'src/view/convenienceFee/importer/ConvenienceFeeImporterPage'
      ),
    permissionRequired: permissions.convenienceFeeImport,
    exact: true,
  },
  {
    path: '/convenience-fee/:id/edit',
    loader: () =>
      import('src/view/convenienceFee/form/ConvenienceFeeFormPage'),
    permissionRequired: permissions.convenienceFeeEdit,
    exact: true,
  },
  {
    path: '/convenience-fee/:id',
    loader: () =>
      import('src/view/convenienceFee/view/ConvenienceFeeViewPage'),
    permissionRequired: permissions.convenienceFeeRead,
    exact: true,
  },

  {
    path: '/shop-owner',
    loader: () =>
      import('src/view/shopOwner/list/ShopOwnerListPage'),
    permissionRequired: permissions.shopOwnerRead,
    exact: true,
  },
  {
    path: '/shop-owner/new',
    loader: () =>
      import('src/view/shopOwner/form/ShopOwnerFormPage'),
    permissionRequired: permissions.shopOwnerCreate,
    exact: true,
  },
  {
    path: '/shop-owner/importer',
    loader: () =>
      import(
        'src/view/shopOwner/importer/ShopOwnerImporterPage'
      ),
    permissionRequired: permissions.shopOwnerImport,
    exact: true,
  },
  {
    path: '/shop-owner/:id/edit',
    loader: () =>
      import('src/view/shopOwner/form/ShopOwnerFormPage'),
    permissionRequired: permissions.shopOwnerEdit,
    exact: true,
  },
  {
    path: '/shop-owner/:id',
    loader: () =>
      import('src/view/shopOwner/view/ShopOwnerViewPage'),
    permissionRequired: permissions.shopOwnerRead,
    exact: true,
  },

  {
    path: '/shop-product',
    loader: () =>
      import('src/view/shopProduct/list/ShopProductListPage'),
    permissionRequired: permissions.shopProductRead,
    exact: true,
  },
  {
    path: '/shop-product/new',
    loader: () =>
      import('src/view/shopProduct/form/ShopProductFormPage'),
    permissionRequired: permissions.shopProductCreate,
    exact: true,
  },
  {
    path: '/shop-product/importer',
    loader: () =>
      import(
        'src/view/shopProduct/importer/ShopProductImporterPage'
      ),
    permissionRequired: permissions.shopProductImport,
    exact: true,
  },
  {
    path: '/shop-product/:id/edit',
    loader: () =>
      import('src/view/shopProduct/form/ShopProductFormPage'),
    permissionRequired: permissions.shopProductEdit,
    exact: true,
  },
  {
    path: '/shop-product/:id',
    loader: () =>
      import('src/view/shopProduct/view/ShopProductViewPage'),
    permissionRequired: permissions.shopProductRead,
    exact: true,
  },

  {
    path: '/shop',
    loader: () =>
      import('src/view/shop/list/ShopListPage'),
    permissionRequired: permissions.shopRead,
    exact: true,
  },
  {
    path: '/shop/new',
    loader: () =>
      import('src/view/shop/form/ShopFormPage'),
    permissionRequired: permissions.shopCreate,
    exact: true,
  },
  {
    path: '/shop/importer',
    loader: () =>
      import(
        'src/view/shop/importer/ShopImporterPage'
      ),
    permissionRequired: permissions.shopImport,
    exact: true,
  },
  {
    path: '/shop/:id/edit',
    loader: () =>
      import('src/view/shop/form/ShopFormPage'),
    permissionRequired: permissions.shopEdit,
    exact: true,
  },
  {
    path: '/shop/:id',
    loader: () =>
      import('src/view/shop/view/ShopViewPage'),
    permissionRequired: permissions.shopRead,
    exact: true,
  },

  {
    path: '/shop-product-category',
    loader: () =>
      import('src/view/shopProductCategory/list/ShopProductCategoryListPage'),
    permissionRequired: permissions.shopProductCategoryRead,
    exact: true,
  },
  {
    path: '/shop-product-category/new',
    loader: () =>
      import('src/view/shopProductCategory/form/ShopProductCategoryFormPage'),
    permissionRequired: permissions.shopProductCategoryCreate,
    exact: true,
  },
  {
    path: '/shop-product-category/importer',
    loader: () =>
      import(
        'src/view/shopProductCategory/importer/ShopProductCategoryImporterPage'
      ),
    permissionRequired: permissions.shopProductCategoryImport,
    exact: true,
  },
  {
    path: '/shop-product-category/:id/edit',
    loader: () =>
      import('src/view/shopProductCategory/form/ShopProductCategoryFormPage'),
    permissionRequired: permissions.shopProductCategoryEdit,
    exact: true,
  },
  {
    path: '/shop-product-category/:id',
    loader: () =>
      import('src/view/shopProductCategory/view/ShopProductCategoryViewPage'),
    permissionRequired: permissions.shopProductCategoryRead,
    exact: true,
  },

  {
    path: '/advertisements',
    loader: () =>
      import('src/view/advertisements/list/AdvertisementsListPage'),
    permissionRequired: permissions.advertisementsRead,
    exact: true,
  },
  {
    path: '/advertisements/new',
    loader: () =>
      import('src/view/advertisements/form/AdvertisementsFormPage'),
    permissionRequired: permissions.advertisementsCreate,
    exact: true,
  },
  {
    path: '/advertisements/importer',
    loader: () =>
      import(
        'src/view/advertisements/importer/AdvertisementsImporterPage'
      ),
    permissionRequired: permissions.advertisementsImport,
    exact: true,
  },
  {
    path: '/advertisements/:id/edit',
    loader: () =>
      import('src/view/advertisements/form/AdvertisementsFormPage'),
    permissionRequired: permissions.advertisementsEdit,
    exact: true,
  },
  {
    path: '/advertisements/:id',
    loader: () =>
      import('src/view/advertisements/view/AdvertisementsViewPage'),
    permissionRequired: permissions.advertisementsRead,
    exact: true,
  },

  {
    path: '/advert-payment',
    loader: () =>
      import('src/view/advertPayment/list/AdvertPaymentListPage'),
    permissionRequired: permissions.advertPaymentRead,
    exact: true,
  },
  {
    path: '/advert-payment/new',
    loader: () =>
      import('src/view/advertPayment/form/AdvertPaymentFormPage'),
    permissionRequired: permissions.advertPaymentCreate,
    exact: true,
  },
  {
    path: '/advert-payment/importer',
    loader: () =>
      import(
        'src/view/advertPayment/importer/AdvertPaymentImporterPage'
      ),
    permissionRequired: permissions.advertPaymentImport,
    exact: true,
  },
  {
    path: '/advert-payment/:id/edit',
    loader: () =>
      import('src/view/advertPayment/form/AdvertPaymentFormPage'),
    permissionRequired: permissions.advertPaymentEdit,
    exact: true,
  },
  {
    path: '/advert-payment/:id',
    loader: () =>
      import('src/view/advertPayment/view/AdvertPaymentViewPage'),
    permissionRequired: permissions.advertPaymentRead,
    exact: true,
  },

  {
    path: '/advert-package',
    loader: () =>
      import('src/view/advertPackage/list/AdvertPackageListPage'),
    permissionRequired: permissions.advertPackageRead,
    exact: true,
  },
  {
    path: '/advert-package/new',
    loader: () =>
      import('src/view/advertPackage/form/AdvertPackageFormPage'),
    permissionRequired: permissions.advertPackageCreate,
    exact: true,
  },
  {
    path: '/advert-package/importer',
    loader: () =>
      import(
        'src/view/advertPackage/importer/AdvertPackageImporterPage'
      ),
    permissionRequired: permissions.advertPackageImport,
    exact: true,
  },
  {
    path: '/advert-package/:id/edit',
    loader: () =>
      import('src/view/advertPackage/form/AdvertPackageFormPage'),
    permissionRequired: permissions.advertPackageEdit,
    exact: true,
  },
  {
    path: '/advert-package/:id',
    loader: () =>
      import('src/view/advertPackage/view/AdvertPackageViewPage'),
    permissionRequired: permissions.advertPackageRead,
    exact: true,
  },

  {
    path: '/ad-package-order',
    loader: () =>
      import('src/view/adPackageOrder/list/AdPackageOrderListPage'),
    permissionRequired: permissions.adPackageOrderRead,
    exact: true,
  },
  {
    path: '/ad-package-order/new',
    loader: () =>
      import('src/view/adPackageOrder/form/AdPackageOrderFormPage'),
    permissionRequired: permissions.adPackageOrderCreate,
    exact: true,
  },
  {
    path: '/ad-package-order/importer',
    loader: () =>
      import(
        'src/view/adPackageOrder/importer/AdPackageOrderImporterPage'
      ),
    permissionRequired: permissions.adPackageOrderImport,
    exact: true,
  },
  {
    path: '/ad-package-order/:id/edit',
    loader: () =>
      import('src/view/adPackageOrder/form/AdPackageOrderFormPage'),
    permissionRequired: permissions.adPackageOrderEdit,
    exact: true,
  },
  {
    path: '/ad-package-order/:id',
    loader: () =>
      import('src/view/adPackageOrder/view/AdPackageOrderViewPage'),
    permissionRequired: permissions.adPackageOrderRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  // {
  //   path: '/',
  //   loader: () => import('src/view/landing'),
  // },
  {
    path: '/',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },

  {
    path: '/signupmech',
    loader: () => import('src/view/auth/SignupMechanicPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const phoneUnverifiedRoutes = [
  {
    path: '/auth/phone-unverified',
    loader: () =>
      import('src/view/auth/PhoneUnverifiedPage'),
  },
].filter(Boolean)

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  phoneUnverifiedRoutes,
  simpleRoutes,
};
