import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.servicesOffered.fields.id'),
  },
  {
    name: 'servicesName',
    label: i18n('entities.servicesOffered.fields.servicesName'),
  },
  {
    name: 'servicePrice',
    label: i18n('entities.servicesOffered.fields.servicePrice'),
  },
  {
    name: 'allowsFreeCheckout',
    label: i18n('entities.servicesOffered.fields.allowsFreeCheckout'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'requiredCheckoutAmount',
    label: i18n('entities.servicesOffered.fields.requiredCheckoutAmount'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.servicesOffered.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.servicesOffered.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
