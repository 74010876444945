import list from 'src/modules/car/list/carListReducers';
import form from 'src/modules/car/form/carFormReducers';
import view from 'src/modules/car/view/carViewReducers';
import destroy from 'src/modules/car/destroy/carDestroyReducers';
import importerReducer from 'src/modules/car/importer/carImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
