import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'advertPackageName',
    label: i18n('entities.advertPackage.fields.advertPackageName'),
    schema: schemas.string(
      i18n('entities.advertPackage.fields.advertPackageName'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'advertPackageDescription',
    label: i18n('entities.advertPackage.fields.advertPackageDescription'),
    schema: schemas.string(
      i18n('entities.advertPackage.fields.advertPackageDescription'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'advertPackageNoOfProducts',
    label: i18n('entities.advertPackage.fields.advertPackageNoOfProducts'),
    schema: schemas.integer(
      i18n('entities.advertPackage.fields.advertPackageNoOfProducts'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'advertPackageCostOfPackage',
    label: i18n('entities.advertPackage.fields.advertPackageCostOfPackage'),
    schema: schemas.integer(
      i18n('entities.advertPackage.fields.advertPackageCostOfPackage'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'advertPackageDuration',
    label: i18n('entities.advertPackage.fields.advertPackageDuration'),
    schema: schemas.integer(
      i18n('entities.advertPackage.fields.advertPackageDuration'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'advertPackagePriorityLevel',
    label: i18n('entities.advertPackage.fields.advertPackagePriorityLevel'),
    schema: schemas.integer(
      i18n('entities.advertPackage.fields.advertPackagePriorityLevel'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'advertPackageRadiusDistance',
    label: i18n('entities.advertPackage.fields.advertPackageRadiusDistance'),
    schema: schemas.integer(
      i18n('entities.advertPackage.fields.advertPackageRadiusDistance'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'advertPackageAdvertisement',
    label: i18n('entities.advertPackage.fields.advertPackageAdvertisement'),
    schema: schemas.relationToMany(
      i18n('entities.advertPackage.fields.advertPackageAdvertisement'),
      {},
    ),
  },
  {
    name: 'advertPackageForShopOwner',
    label: i18n('entities.advertPackage.fields.advertPackageForShopOwner'),
    schema: schemas.relationToMany(
      i18n('entities.advertPackage.fields.advertPackageForShopOwner'),
      {},
    ),
  },
];