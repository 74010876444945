import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import adPackageOrderEnumerators from 'src/modules/adPackageOrder/adPackageOrderEnumerators';

export default [
  {
    name: 'adOrderBillingFirstName',
    label: i18n('entities.adPackageOrder.fields.adOrderBillingFirstName'),
    schema: schemas.string(
      i18n('entities.adPackageOrder.fields.adOrderBillingFirstName'),
      {},
    ),
  },
  {
    name: 'adOrderBillingLastName',
    label: i18n('entities.adPackageOrder.fields.adOrderBillingLastName'),
    schema: schemas.string(
      i18n('entities.adPackageOrder.fields.adOrderBillingLastName'),
      {},
    ),
  },
  {
    name: 'adOrderForPackageId',
    label: i18n('entities.adPackageOrder.fields.adOrderForPackageId'),
    schema: schemas.relationToOne(
      i18n('entities.adPackageOrder.fields.adOrderForPackageId'),
      {},
    ),
  },
  {
    name: 'adOrderOfLoggedInUserId',
    label: i18n('entities.adPackageOrder.fields.adOrderOfLoggedInUserId'),
    schema: schemas.relationToOne(
      i18n('entities.adPackageOrder.fields.adOrderOfLoggedInUserId'),
      {},
    ),
  },
  {
    name: 'adOrderBillingPhoneNumber',
    label: i18n('entities.adPackageOrder.fields.adOrderBillingPhoneNumber'),
    schema: schemas.string(
      i18n('entities.adPackageOrder.fields.adOrderBillingPhoneNumber'),
      {},
    ),
  },
  {
    name: 'adOrderBillingEmail',
    label: i18n('entities.adPackageOrder.fields.adOrderBillingEmail'),
    schema: schemas.string(
      i18n('entities.adPackageOrder.fields.adOrderBillingEmail'),
      {},
    ),
  },
  {
    name: 'adOrderCompanyName',
    label: i18n('entities.adPackageOrder.fields.adOrderCompanyName'),
    schema: schemas.string(
      i18n('entities.adPackageOrder.fields.adOrderCompanyName'),
      {},
    ),
  },
  {
    name: 'adOrderPaymentId',
    label: i18n('entities.adPackageOrder.fields.adOrderPaymentId'),
    schema: schemas.relationToOne(
      i18n('entities.adPackageOrder.fields.adOrderPaymentId'),
      {},
    ),
  },
  {
    name: 'adOrderStatus',
    label: i18n('entities.adPackageOrder.fields.adOrderStatus'),
    schema: schemas.enumerator(
      i18n('entities.adPackageOrder.fields.adOrderStatus'),
      {
        "options": adPackageOrderEnumerators.adOrderStatus
      },
    ),
  },
  {
    name: 'adOrderTotal',
    label: i18n('entities.adPackageOrder.fields.adOrderTotal'),
    schema: schemas.integer(
      i18n('entities.adPackageOrder.fields.adOrderTotal'),
      {},
    ),
  },
  {
    name: 'adOrderBillingAdditionalInfo',
    label: i18n('entities.adPackageOrder.fields.adOrderBillingAdditionalInfo'),
    schema: schemas.string(
      i18n('entities.adPackageOrder.fields.adOrderBillingAdditionalInfo'),
      {},
    ),
  },
];