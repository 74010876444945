import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.reviewsOfGarages.fields.id'),
  },
  {
    name: 'reviewOfGarage',
    label: i18n('entities.reviewsOfGarages.fields.reviewOfGarage'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'garageReviewRating',
    label: i18n('entities.reviewsOfGarages.fields.garageReviewRating'),
  },
  {
    name: 'comments',
    label: i18n('entities.reviewsOfGarages.fields.comments'),
  },
  {
    name: 'byCustomer',
    label: i18n('entities.reviewsOfGarages.fields.byCustomer'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.reviewsOfGarages.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.reviewsOfGarages.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
