import ConvenienceFeeService from 'src/modules/convenienceFee/convenienceFeeService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'CONVENIENCEFEE_VIEW';

const convenienceFeeViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: convenienceFeeViewActions.FIND_STARTED,
      });

      const record = await ConvenienceFeeService.find(id);

      dispatch({
        type: convenienceFeeViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: convenienceFeeViewActions.FIND_ERROR,
      });

      getHistory().push('/convenience-fee');
    }
  },
};

export default convenienceFeeViewActions;
