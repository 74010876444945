import list from 'src/modules/convenienceFee/list/convenienceFeeListReducers';
import form from 'src/modules/convenienceFee/form/convenienceFeeFormReducers';
import view from 'src/modules/convenienceFee/view/convenienceFeeViewReducers';
import destroy from 'src/modules/convenienceFee/destroy/convenienceFeeDestroyReducers';
import importerReducer from 'src/modules/convenienceFee/importer/convenienceFeeImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
