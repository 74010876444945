import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/advertPayment/importer/advertPaymentImporterSelectors';
import AdvertPaymentService from 'src/modules/advertPayment/advertPaymentService';
import fields from 'src/modules/advertPayment/importer/advertPaymentImporterFields';
import { i18n } from 'src/i18n';

const advertPaymentImporterActions = importerActions(
  'ADVERTPAYMENT_IMPORTER',
  selectors,
  AdvertPaymentService.import,
  fields,
  i18n('entities.advertPayment.importer.fileName'),
);

export default advertPaymentImporterActions;