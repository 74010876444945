import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import clientsResponseToMechanicResponseEnumerators from 'src/modules/clientsResponseToMechanicResponse/clientsResponseToMechanicResponseEnumerators';

export default [
  {
    name: 'response',
    label: i18n('entities.clientsResponseToMechanicResponse.fields.response'),
    schema: schemas.enumerator(
      i18n('entities.clientsResponseToMechanicResponse.fields.response'),
      {
        "required": true,
        "options": clientsResponseToMechanicResponseEnumerators.response
      },
    ),
  },
  {
    name: 'reason',
    label: i18n('entities.clientsResponseToMechanicResponse.fields.reason'),
    schema: schemas.string(
      i18n('entities.clientsResponseToMechanicResponse.fields.reason'),
      {
        "max": 380
      },
    ),
  },
  {
    name: 'documents',
    label: i18n('entities.clientsResponseToMechanicResponse.fields.documents'),
    schema: schemas.files(
      i18n('entities.clientsResponseToMechanicResponse.fields.documents'),
      {
        "max": 3
      },
    ),
  },
  {
    name: 'clientResponseToMechanicResponse',
    label: i18n('entities.clientsResponseToMechanicResponse.fields.clientResponseToMechanicResponse'),
    schema: schemas.relationToOne(
      i18n('entities.clientsResponseToMechanicResponse.fields.clientResponseToMechanicResponse'),
      {},
    ),
  },
];