import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/garage/importer/garageImporterSelectors';
import GarageService from 'src/modules/garage/garageService';
import fields from 'src/modules/garage/importer/garageImporterFields';
import { i18n } from 'src/i18n';

const garageImporterActions = importerActions(
  'GARAGE_IMPORTER',
  selectors,
  GarageService.import,
  fields,
  i18n('entities.garage.importer.fileName'),
);

export default garageImporterActions;