import list from 'src/modules/clientOrder/list/clientOrderListReducers';
import form from 'src/modules/clientOrder/form/clientOrderFormReducers';
import view from 'src/modules/clientOrder/view/clientOrderViewReducers';
import destroy from 'src/modules/clientOrder/destroy/clientOrderDestroyReducers';
import importerReducer from 'src/modules/clientOrder/importer/clientOrderImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
